<div class="container-fluid mt-4">
    <div class="row">
        <!-- Bootstrap col-lg-2 (instead of col-lg-3) for the left sidebar to reduce its width -->
        <!-- Left Side - Sidebar -->
        <div class="col-lg-2 col-md-3 col-sm-12">
            <!-- Status items -->
            <div *ngFor="let item of status" class="mb-4 d-flex align-items-center cursor-hover">
                
                    <img loading="lazy" [src]="item.icon" height="25px" />
            

                <div class="flex-grow-1 status-item m-2" [routerLink]="item.route" routerLinkActive="custom-text-color">
                    {{ item.title }}
                </div>

               
            </div>
        </div>

        <!-- Right Side - Main Content Area -->
        <div class="col-lg-10 col-md-9 col-sm-12 pt-lg-0 pt-md-4">
            <router-outlet></router-outlet>
        </div>
    </div>
</div>
