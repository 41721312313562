<div style="font-family: Baskervville" class="min-vh-60 p-5 contact-div">
  <h2 class="fw-600" style="font-family: Baskervville">Privacy & Policy</h2>
  <div class="mt-4">
    <ol class="ol-box">
      <li>
        <h3 class="fw-600">1. Introduction.</h3>
        <ul>
          <p>
            Welcome to Weddingaro.com! We are committed to protecting your
            personal data and respecting your privacy. This privacy policy
            explains how we collect, use, and share your information when you
            use our mobile app and services.
          </p>
        </ul>
      </li>
      <li>
        <h3 class="fw-600">2. Information We Collect.</h3>
        <ol>
          <li>
            <h4 class="fw-600">2.1 Personal Information.</h4>
            <p>
              When you register, we collect information such as your name, email
              address, phone number, and profile photo.
            </p>
          </li>
          <li>
            <h4 class="fw-600">2.2 Usage Data.</h4>
            <p>
              We collect data on how you interact with our app, such as search
              queries, clicks, and preferences.
            </p>
          </li>
          <li>
            <h4 class="fw-600">2.3 Device Information.</h4>
            <p>
              We collect information about your device, including IP address,
              operating system, and browser type.
            </p>
          </li>
        </ol>
      </li>
      <li>
        <h3 class="fw-600">3. How We Use Your Information.</h3>

        <ol>
          <li>
            <h4 class="fw-600">3.1 To Provide Services.</h4>
            <p>
              We use your data to facilitate connections between users and
              vendors, provide personalized recommendations, and improve our
              services.
            </p>
          </li>
          <li>
            <h4 class="fw-600">3.2 Communication.</h4>
            <p>
              We use your email to send newsletters, updates, and promotional
              offers. You can opt-out at any time.
            </p>
          </li>
          <li>
            <h4 class="fw-600">3.3 Analytics.</h4>
            <p>
              We analyze usage data to understand how our app is used and to
              improve user experience.
            </p>
          </li>
        </ol>
      </li>
      <li>
        <h3 class="fw-600">4. Sharing Your Information.</h3>
        <ol>
          <li>
            <h4 class="fw-600">4.1 With Vendors.</h4>
            <p>
              We share your contact information with vendors when you express
              interest in their services.
            </p>
          </li>
          <li>
            <h4 class="fw-600">4.2 Third-Party Services.</h4>
            <p>
              We may use third-party services for analytics and communication.
              These services are bound by their privacy policies.
            </p>
          </li>
          <li>
            <h4 class="fw-600">4.3 Legal Requirements.</h4>
            <p>
              We may disclose your information if required by law or to protect
              our rights.
            </p>
          </li>
        </ol>
      </li>
      <li>
        <h3 class="fw-600">5. User Rights and Controls.</h3>
        <ol>
          <li>
            <h4 class="fw-600">5.1 Access and Correction.</h4>
            <p>
              You can access and update your personal information through your
              account settings.
            </p>
          </li>
          <li>
            <h4 class="fw-600">5.2 Deletion.</h4>
            <p>
              You can delete your account by contacting us at
              hello@Weddingaro.com.
            </p>
          </li>
          <li>
            <h4 class="fw-600">5.3 Opt-Out.</h4>
            <p>
              You can opt-out of receiving promotional emails by following the
              instructions in the email.
            </p>
          </li>
        </ol>
      </li>
      <li>
        <h3 class="fw-600">6. Security.</h3>
        <ul>
          <p>
            We implement security measures to protect your data from
            unauthorized access, alteration, and disclosure. However, no method
            of transmission over the internet or electronic storage is
            completely secure.
          </p>
        </ul>
      </li>
      <li>
        <h3 class="fw-600">7. Cookies.</h3>
        <ul>
          <p>
            We use cookies to enhance your experience on our app. You can
            control cookies through your browser settings.
          </p>
        </ul>
      </li>
      <li>
        <h3 class="fw-600">8. Changes to This Policy.</h3>
        <ul>
          <p>
            We may update this policy from time to time. We will notify you of
            any significant changes by posting the new policy on our app and
            updating the effective date.
          </p>
        </ul>
      </li>
      <li>
        <h3 class="fw-600">9. Contact Us.</h3>
        <ul>
          <div>
            If you have any questions about this privacy policy, please contact
            us at:
            <ul type="circle">
              <li>Trifecta Net Private Limited</li>
              <li>Operator of the Weddingaro.com Website</li>
              <li>
                37 Siris Road, DLF Phase III, Gurugram-122002, Haryana, India
              </li>
              <li>Telephone: 9999575044</li>
              <li>Email: hello@Weddingaro.com</li>
            </ul>
          </div>
        </ul>
      </li>
    </ol>
  </div>
</div>
