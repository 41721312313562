<div class="main-container">
  <div
    #prev
    class="carousel-button custom-text-color"
    (click)="prevClickHandler(prev)"
  >
    <i style="font-size: 26px" class="icon fas fa-angle-left"></i>
  </div>

  <ng-content></ng-content>

  <div
    #next
    class="carousel-button custom-text-color"
    (click)="nextClickHandler(next)"
  >
    <i class="icon fas fa-angle-right" style="font-size: 26px"></i>
  </div>
</div>

