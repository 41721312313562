<div class="container rounded border p-2">
    <div class="text-center fs-35 fw-400 mb-4" style="font-family: Baskervville;">
        Budget
    </div>
    <div class="container">
        <div class="d-flex justify-content-center align-items-center">
            <!-- First Element -->
            <div class="text-center m-4">
                <img loading="lazy" class="m-2" src="/assets/images/Accounting.svg" alt="" height="60px" width="60px">
                <div class="m-2 fs-17 ">
                    ESTIMATED BUDGET
                </div>
                <div class="m-2 fs-30 fw-500">
                    20,00,000
                </div>
                <p class="text-white fs-17 custom-text-color px-4 cursor-hover-red m-0">
                    Edit Budget
                </p>

            </div>

            <!-- Vertical Line -->
            <div class="vertical-line m-4" style="border-left: 1px solid black; height: 241px;"></div>

            <!-- Second Element -->
            <div class="text-center m-4">
                <img loading="lazy" class="m-2" src="/assets/images/Coins.svg" alt="" height="60px" width="60px">
                <div class="m-2">
                    FINAL COST
                </div>
                <div class="m-2 fs-30 fw-500">
                    20,00,000
                </div>
                <div class="d-flex justify-content-center">
                    <div class="m-2" >
                        Paid:  ₹ 0    
                    </div>
                    <div class="m-2" >
                        Pending:  ₹ 0 
                    </div>



                </div>

            </div>
        </div>
        <hr>
        <div class="text-center fs-35 fw-400 mb-4" >
            Expenses
        </div>

        <!-- <div style="display: block;">
            <canvas baseChart
                    [data]="chartData"
                    [labels]="chartLabels"
                    [chartType]="chartType"
                    [options]="chartOptions"
            ></canvas>
          </div>
           -->

    </div>

</div>