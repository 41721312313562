<div class="md-img m-5">
    <div class="image-container" >
        <img loading="lazy" src={{imgurl}} alt="" />
        <div class="centered-card" [ngStyle]="{'width': width}">
            <div class="card-body" >
                <div class="text-center fw-400 " style="font-family: Baskervville; font-size: 45px;">
                    {{ title }}
                </div>
                <div class="text-center fw-400 fs-20 mt-2" style="font-family: Baskervville;">
                    {{ description }}
                </div>
                <button type="button" class="btn btn-primary custom-button-dark m-3 " >

                   
    
                    <span class="px-2 fs-15">{{ btntext }}</span>
    
                </button>
            </div>
        </div>
    </div>
</div>