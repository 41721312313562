<div class="container">
  <div class="row justify-content-start">
    <div
      class="align-content-start text-black fs-3 mb-3 ff-b"
    >
      Settings
    </div>

    <div class="card p-5 chart-container border-1 shadow rounded-4">
      <form>
        <div class="row">
          <div class="text-black fs-30 fw-500" >
            Notifications
          </div>
          <div class="mt-1 fs-20">
            Our emails contain useful information and tips to help you improve
            your Storefront and grow your business.You can edit your email
            preferences at any time.
          </div>
          <hr class="mb-3 mt-3" />

          <label
            class="custom-checkbox d-flex align-items-center custom-mat-checkbox mt-2"
          >
            <mat-checkbox class="mr-2"></mat-checkbox>
            <div class="text-black fs-6">
              Communications regarding Campus educational content.
            </div>
          </label>
          <label
            class="custom-checkbox d-flex align-items-center custom-mat-checkbox mt-2"
          >
            <mat-checkbox class="mr-2"></mat-checkbox>
            <div class="text-black fs-6">
              Training emails during your first week
            </div>
          </label>
          <label
            class="custom-checkbox d-flex align-items-center custom-mat-checkbox mt-2"
          >
            <mat-checkbox class="mr-2"></mat-checkbox>
            <div class="text-black fs-6">
              Promotional communications about our products/services, events,
              giveaways, and exclusive discounts.
            </div>
          </label>
        </div>
        <button class="save-button">Save</button>
      </form>
    </div>
  </div>
</div>
