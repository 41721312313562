<div class="border-1 border border-secondary-subtle mt-5 p-3 rounded-3">
  <div class="table-responsive">
    <table class="w-100">
      <thead class="group-th">
        <tr class="">
          <th class="group-table-image align-items-center">
            <div class="fs-4">{{ title | titlecase }}</div>
            <div class="text-secondary">{{ groups.length }}</div>
          </th>
          <th>ATTENDANCE</th>
          <th>MENU</th>
          <th></th>
        </tr>
      </thead>
      <tbody class="tbody-select">
        <tr *ngFor="let group of groups">
          <td class="group-table-image">
            <mat-checkbox class=""></mat-checkbox><img loading="lazy" src="{{ group.image }}" width="50px" alt="" />
            <div>{{ group.name }}</div>
          </td>
          <td>
            <select (change)="updateAttendance($event,group._id)" class="form-select">
              <option value="" [defaultSelected]="" >Select..</option>
              <option value="Pending" [selected]="group.attendance == 'Pending'">Pending</option>
              <option value="Attending" [selected]="group.attendance == 'Attending'">Attending</option>
              <option value="Declined" [selected]="group.attendance == 'Declined'">Not Attending</option>
            </select>
          </td>
          <td>
            {{getMenu(group.menues)}}
          </td>
          <td class="td-button d-flex justify-content-end gap-4">
            <button (click)="openEditModal(group)" class="btn btn-primary">
              <img loading="lazy" src="../../../../../../assets/images/Edit.svg"  />
            </button>
            <button (click)="deleteGuest(group._id)" class="btn btn-danger">
              <img loading="lazy" src="../../../../../../assets/images/Waste.svg" />
            </button>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</div>
