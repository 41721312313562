<div class="min-vh-60 p-5 contact-div">
  <h2 class="fw-600" style="font-family: Baskervville">Career With Us</h2>
  <div class="text-center mt-4">
    <h2>JOIN OUR TEAM</h2>
    <p>
      We believe that even though we're so often communicating and socialising
      online, it’s those offline weddings, social and corporate events and
      activities that are most important. So we are starting off by making it
      easier for people to organise offline weddings, social and corporate
      events and find venues, artists, decorators, entertainers etc. for the
      same. What we have created is a web-based venue and vendor discovery
      platform that links event-organisers with the best function venues and
      vendors across India. If you want to contribute towards our big hairy
      audacious goal to make it easier for Indians to create social experiences,
      you should definitely join us! You can send in your resume at:

       <a
        href="mailto: hello@weeing.com"
        class="text-dark fw-600"
      >
        <i class="fa-solid fa-envelope me-2"></i>
        hello@weeing.com
      </a>
    </p>
  </div>
</div>
