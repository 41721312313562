<div class="my-2">
  <p class="fs-17 text-black-50 cursor-hover-red" routerLink="/dashboard/storefront/storefrontStatus" >
    Your storefront status {{ status !== null ? status + '%' : '0' }}
  </p>
  <app-progress-bar [progress]="status !== null ? status : 0"></app-progress-bar>
  

</div>

<div class="m-3"></div>

<div class="img-card shadow border my-2">
  <img loading="lazy" src="https://weddingaro.s3.ap-south-1.amazonaws.com/side-images/storefront-demo.png" alt="">
  <div class="w-100 text-center mt-1">
    View Storefront
  </div>
</div>
<div class="mt-4">
  <ul class="list-unstyled fw-light">
    <li *ngFor="let item of routeCard" class="p-2 my-2 rounded cursor fs-12" style="background: #F335351A;" [routerLink]="item.route" >
      {{item.title}}
    </li>
  </ul>
</div>
