<div class="container">
    <div class="row justify-content-start">
      <div
        class="align-content-start text-black fs-3 mb-3 ff-b"
      >
        Settings
      </div>
  
      <div class="card p-5 chart-container border-1 shadow rounded-4">
        <form>
          <div class="row">
            <div class="text-black wfs-30 fw-500" >
              Notifications
            </div>
            <div class="mt-1 wfs-20">
              Hello <strong>Admin</strong> welcome to settings set your priorities but mark up the checks according to you. 
            </div>
            <hr class="mb-3 mt-3" />
  
            <label
              class="custom-checkbox d-flex align-items-center custom-mat-checkbox mt-2"
            >
              <mat-checkbox class="mr-2"></mat-checkbox>
              <div class="text-black wfs-17">
                Send every all the mail when user comes to your web.
              </div>
            </label>
            <label
              class="custom-checkbox d-flex align-items-center custom-mat-checkbox mt-2"
            >
              <mat-checkbox class="mr-2"></mat-checkbox>
              <div class="text-black wfs-17">
                Whatapp Messager Apllicaple to this website.
              </div>
            </label>
            <label
              class="custom-checkbox d-flex align-items-center custom-mat-checkbox mt-2"
            >
              <mat-checkbox class="mr-2"></mat-checkbox>
              <div class="text-black wfs-17">
                Promotional communications about our products/services, events,
                giveaways, and exclusive discounts. [in working]
              </div>
            </label>
          </div>
          <button class="save-button">Save</button>
        </form>
      </div>
    </div>
  </div>
  