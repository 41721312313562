import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { UserService } from 'src/app/services/user.service';
import { imgLink } from 'src/assets/imgLinks';

@Component({
  selector: 'app-wedding-venue-page',
  templateUrl: './wedding-venue-page.component.html',
  styleUrls: ['./wedding-venue-page.component.scss'],
})

export class WeddingVenuePageComponent implements OnInit {
  params: any = {};
  sectorId: string = ''
  path: any

  constructor(private route: ActivatedRoute, private userservice: UserService, private router: Router) {
    this.route.paramMap.subscribe(params => {
      this.sectorId = params.get('sectorId') || '';
      this.categoryId = params.get('categoryId') || '';

      const navigationState = this.router.getCurrentNavigation()?.extras.state;
      if (navigationState) {
        this.sectorId = navigationState.sectorId || '';
        this.categoryId = navigationState.categoryId || '';
      }

      this.fetchStoresBycategoryId(this.categoryId);
      console.log("wedding venue sect id : ", this.sectorId);
      console.log("wedding venue cat id : ", this.categoryId);
    });
  }
  categoryId: string = '';
  location = ''




  venuesByCity: any = [

  ];
  venuesList = [

  ];
  

  ngOnInit(): void {

  }
  searchVenues(event: any) {
    this.fetchStoresBycategoryId(event.categoryId, event.location)
  }

  fetchStoresBycategoryId(categoryId: string, location = "", page = 1) {
    this.location = location;
    this.userservice.getAllStores(categoryId, this.sectorId, location, page).subscribe((res: any) => {
      let data = res?.stores;
      let vendors: any = data.map((store: any) => {
        return {
          name: store?.businessdetails['businessName'],
          img: store.photos && store.photos.length ? store.photos.map((photo: any) => photo.url) : imgLink.noImageAvailble,
          rating: 4, // get rating here from somewhere in future
          address: store?.location?.city,
          lowestFood: store?.faq?.platePricing,
          id: store._id,
          state: store?.location?.city,
          category: store.businessdetails.category._id,
          capacity: {
            min: store?.faq?.guestAccomodation.min, max: store?.faq?.guestAccomodation.max

          },
        }
      })
      this.venuesList = vendors
      // this is the code to sort the stores by city 
      // let states = [...new Set(vendors.map((item: any) => item?.state))]
      // let data2: any = []
      // states.forEach(state => {
      //   data2.push({ 'city': state, venues: vendors.filter((item: any) => item?.state == state) })
      // })
      // data2 = data2.sort((a: any, b: any) => b.venues.length - a.venues.length)
      this.venuesByCity = vendors;
      this.totalPages = res?.pagination?.totalPage;
      this.currentPage = res?.pagination?.page;
      this.totalstores = res?.pagination?.totalStores;
    });
  }

  currentPage: number = 1;
  totalPages: number = 1;
  totalstores: number = 0;

  onPaginationClick(pageNumber: number | 'previous' | 'next') {
    if (pageNumber === 'previous' && this.currentPage > 1) {
      this.currentPage--;
    } else if (pageNumber === 'next' && this.currentPage < this.totalPages) {
      this.currentPage++;
    } else if (typeof pageNumber === 'number') {
      this.currentPage = pageNumber;
    }

    this.fetchStoresBycategoryId(this.categoryId, this.location, this.currentPage);
    document.body.scrollTop = 100; 
    document.documentElement.scrollTop = 100;
  }
}
