import { Component } from '@angular/core';

@Component({
  selector: 'app-admin-store',
  templateUrl: './admin-store.component.html',
  styleUrls: ['./admin-store.component.scss']
})
export class AdminStoreComponent {

}
