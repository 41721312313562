<div class="min-vh-60 p-5 contact-div">
  <!-- <h2 class="fw-600" style="font-family: Baskervville">Contact Us</h2> -->
  <div class="mt-4 d-flex gap-5">
    <div
      class="d-flex flex-column gap-3 text-white justify-content-center align-items-center"
    >
      <h1
        style="font-family: Baskervville text-black-50"
        class="fw-600 text-black-50"
      >
        Contact Us
      </h1>
      <img
        loading="lazy"
        src="/assets/images/logo_desktop.png"
        routerLink="/home"
        alt="Logo"
        height="75"
        class="d-inline-block align-text-top"
      />
      <a
        href="tel:+917290006773"
        style="text-decoration: none"
        class="text-white fs-24 fw-600 text-black-50"
      >
        <i class="fa-solid fa-phone me-2 text-black-50"></i> Call +91 7290006773
      </a>
      <a
        href="mailto: hello@weddingaro.com"
        style="text-decoration: none"
        class="text-white fs-24 fw-600 text-black-50"
      >
        <i class="fa-solid fa-envelope me-2"></i>
        hello@weddingaro.com
      </a>
      <ul class="text-start list-unstyled fw-light lh-lg">
        <li>
          <a
            href="https://www.facebook.com/profile.php?id=100084042096560"
            target="_blank"
          >
            <img
              loading="lazy"
              class="mx-1"
              src="/assets/images/Facebook.png"
              alt=""
              srcset=""
            />
          </a>
          <a href="https://twitter.com/weddingaro8" target="_blank">
            <img
              loading="lazy"
              class="mx-1"
              src="/assets/images/Twitter.png"
              alt=""
              srcset=""
            />
          </a>
          <a
            href="https://instagram.com/weddingaro8?igshid=MzNlNGNkZWQ4Mg=="
            target="_blank"
          >
            <img
              loading="lazy"
              class="mx-1"
              src="/assets/images/Instagram.png"
              alt=""
              srcset=""
            />
          </a>
        </li>
      </ul>
    </div>
    <div class="contact-form">
      <div>
        <form>
          <!-- Name and Phone Number in one row -->
          <div class="row">
            <div class="col-md-6 mb-3">
              <div class="form-group">
                <label for="name">Name</label>
                <input
                  type="text"
                  class="form-control"
                  id="name"
                  name="name"
                  placeholder="Enter your name"
                  required
                />
              </div>
            </div>
            <div class="col-md-6 mb-3">
              <div class="form-group">
                <label for="phoneNumber">Phone Number</label>
                <input
                  type="number"
                  class="form-control"
                  id="phoneNumber"
                  name="phoneNumber"
                  placeholder="Enter your phone number"
                  required
                />
              </div>
            </div>
          </div>

          <!-- Email -->
          <div class="form-group mb-3">
            <label for="email">Email</label>
            <input
              type="email"
              class="form-control"
              id="email"
              name="email"
              placeholder="Enter your email address"
              required
            />
          </div>

          <!-- Description -->
          <div class="form-group mb-3">
            <label for="description">Description</label>
            <textarea
              class="form-control"
              id="description"
              name="description"
              rows="5"
              placeholder="Enter a description"
              required
            ></textarea>
          </div>

          <!-- Submit Button -->
          <div class="text-center">
            <button class="custom-button px-4 py-2 text-white fs-20">
              Submit
            </button>
          </div>
        </form>
      </div>
    </div>
  </div>
</div>
