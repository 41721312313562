import { Component, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-pagination',
  templateUrl: './pagination.component.html',
  styleUrls: ['./pagination.component.scss']
})
export class PaginationComponent {
  @Input() selectedButton: number = 1;
  @Input() totalPages: number = 1;

  @Output() onClickButton = new EventEmitter<number | 'previous' | 'next'>();

  isPreviousButtonDisabled(): boolean {
    return this.selectedButton === 1;
  }

  isNextButtonDisabled(): boolean {
    return this.selectedButton === this.totalPages;
  }

  onClickButtonHandler(buttonType: 'previous' | 'next' | number) {
    if (buttonType === 'previous' && this.selectedButton > 1) {
      this.selectedButton--;
    } else if (buttonType === 'next' && this.selectedButton < this.totalPages) {
      this.selectedButton++;
    } else if (typeof buttonType === 'number') {
      this.selectedButton = buttonType;
    }

    this.onClickButton.emit(this.selectedButton);
  }

  getPageNumbers(): number[] {
    const totalPagesToShow = 5; // Number of page numbers to show
    const pageNumbers = [];

    let startPage = Math.max(1, this.selectedButton - Math.floor(totalPagesToShow / 2));
    let endPage = Math.min(this.totalPages, startPage + totalPagesToShow - 1);

    if (endPage - startPage + 1 < totalPagesToShow) {
      startPage = Math.max(1, endPage - totalPagesToShow + 1);
    }

    for (let i = startPage; i <= endPage; i++) {
      pageNumbers.push(i);
    }

    return pageNumbers;
  }

}
