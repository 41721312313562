<div class="mx-auto position-relative">
  <img loading="lazy" src="../../../../assets/images/bg.svg" />

  <div class="position-absolute inner-div-out">
    <div class="search-box">
      <app-search-bar></app-search-bar>
    </div>
  </div>
</div>
<div class="page-width">
  <!-- <div class="mb-5">
    <div class="ff-b m-50 text-h">Venues by region</div>
    <div class=""> </div>
  </div> -->
  <!-- <div class="md-img">
      <img loading="lazy" src="../../../../assets/images/middleImg.svg" alt="" />
    </div> -->
  <div class="mx-5">
    <app-custom-slider [g]="20">
      <div class="venue-carousel-wrapper py-2">
        <ng-container>
          <ng-container *ngFor="let venue of storess">
            <app-venue-card [cardInput]="venue"></app-venue-card>
          </ng-container>
        </ng-container>
        <ng-template #noVenues>
          <img loading="lazy" src="https://weddingaro.s3.ap-south-1.amazonaws.com/side-images/no-data.png" alt="No venues available" />
        </ng-template>
      </div>
    </app-custom-slider>
  </div>
  <!-- <div class="my-5">
    <div class="mx-5 venue-heading my-30">
      Listed every type of venue to make your wedding unique
    </div>
    <div class="m-5 d-flex gap-4">
      <a href="#" class="btn custom-text-hover venue-link-button" role="button">Banquet Halls</a>
      <a href="#" class="btn custom-text-hover venue-link-button" role="button">Hotels</a>
      <a href="#" class="btn custom-text-hover venue-link-button" role="button">Marriage Garden</a>
      <a href="#" class="btn custom-text-hover venue-link-button" role="button">Kalyana Mandapams</a>
      <a href="#" class="btn custom-text-hover venue-link-button" role="button">Wedding Resorts</a>
      <a href="#" class="btn custom-text-hover venue-link-button" role="button">Wedding Lawns Farmhouses</a>
    </div>
    <app-custom-slider [g]="20">
      <div class="venue-carousel-wrapper py-2">
        <ng-container *ngFor="let venue of venuesList">
          <app-venue-card [cardInput]="venue"></app-venue-card>
        </ng-container>
      </div>
    </app-custom-slider>
  </div>
  <div class="factors-container mx-5 my-4">
    <div class="mb-10 venue-heading">
      12 Factors to consider before choosing the venue for your wedding
    </div>
    <div class="factors-p">
      Finding the most fitting venue for your wedding ceremonies and
      celebrations can be a daunting task, but we're going to help you narrow
      your search and guide you through the first steps. Are you ready?
    </div>
    <div class="factors-p">
      <p>
        <span> Budget</span>. One of the most deciding factors that will guide
        your venue search is your budget. Once you've got a clear idea of how
        much you can spend on the venue, you'll be able to narrow your search
        and take advantage of your precious time as you make visits.
      </p>
      <p>
        <span> Availability</span>. Once you have a wedding date, it's a good
        idea to ask if the venue is available on your wedding date. If they are
        available and you're clear on your priorities and the services they
        offer are suitable to you, ask how far in advance you can reserve the
        venue. Also ask if they do multiple weddings in one day.
      </p>
      <p>
        <span> Capacity.</span> Next, you'll need to ensure your guest list will
        fit in the venue. Ask how many guests can the venue accommodate, what
        facilities do they offer for pre- and post-wedding events?
      </p>
      <p>
        <span> Accommodation.</span> Does the venue provide accommodation? What
        are the costs and are there deals for large groups? If they don't have
        accommodation on the premises, perhaps they can recommend nearby hotels.
      </p>
      <p>
        <span> Services</span>. What are the services the venue can offer? It's
        a good idea to get a detailed list with pricing to make the best
        decisions going forward. If you prefer your own photographer or caterer
        to the one the venue provides, be sure to ask if you can bring your own
        vendors.
      </p>
      <p>
        <span> Time</span>. It's important to get a precise answer to this
        question. For how many hours can we use this venue as per the quoted
        rental amount? Are there fixed timelines?
      </p>
      <p>
        <span> Rules</span>. It's a good idea to ask about any specific rules
        the venue has, such as noise-reduction, curfews, or protocols that your
        vendors should know about. What spaces do vendors have access to?
      </p>
      <p>
        <span> Facilities</span>. What facilities and equipment can the venue
        supply for your wedding? This could include equipment for the DJ,
        caterer, and anything else you may need. It may seem obvious that
        certain things would be included, but it is better to be sure.
      </p>
      <p>
        <span> Logistics</span>. Are there parking facilities at the venue to
        accommodate all your guests and is there a fee? Is there wheelchair
        access throughout the venue or are there limited areas? What is the
        backup plan if the weather impedes your planned outdoor wedding
        celebration?
      </p>
      <P>
        <span> Menu</span>. If the venue will cater your wedding, ask for prices
        per person, special menus, allergies, and you may also want to ask if
        alcohol is allowed and if it can be arranged? Finally, get detailed
        pricing for everything.
      </P>
      <P>
        <span> Point of contact</span>. Take a close and thorough look at their
        photo gallery to see the quality of their work. If they are decorators,
        for example, their design style should match the kind of wedding
        aesthetic you are going for. If they are photographers, their photos
        should reflect the style of photography you're looking for.
      </P>
      <P>
        <span> Fine print</span>. Go over every possible scenario, extra
        services, over-time fees, cancellation policy and anything else that
        could surprise you when it's time to pay. You should also be informed on
        the payment methods and timings. Once you've covered these points,
        you're ready to book your venue.
      </P>
    </div>
  </div>
  <div class="factors-container mx-5">
    <div class="venue-heading">
      How to find the right wedding venue near you
    </div>
    <div class="factors-p">
      <p>
        Booking your wedding venue is one of the most important decisions in
        your wedding planning journey. The wedding venue you choose can set the
        theme for your wedding celebrations. Do you already have a theme and
        setup in mind? Did you envision a beach wedding or a glamorous night
        with white lights and candles all around in an open-air venue? Some
        couples also want their wedding to be steeped in traditions and prefer
        wedding halls with grand in-house wedding venue decoration. Couples who
        want a royal wedding, book palaces in India that are available to host
        weddings, or choose 5-star hotels that have state-of-the-art
        infrastructure.
      </p>
      <p>
        We have no doubt that you'll find your dream venue among over 8,900 at
        your fingertips in WeddingWire.in. You can find unique wedding venues
        from each and every state of India with our free wedding planning app
        and website, WeddingWire India. Download the WeddingWire India app today
        to find wedding venues near you. You can shortlist the ones that fit the
        bill and send enquiries with the app's Vendor Manager. The WeddingWire
        India app and website make your venue searching journey easier by
        putting together an exhaustive list of different kinds of wedding venues
        that are available on your mobile phone. You can also read about each
        venue, their approximate pricing, and the services they offer, on their
        storefront/profile created on WeddingWire India and read reviews/ratings
        written by other couples explaining their experience of the venue.
      </p>
    </div>
  </div> -->
  <!-- <div class="factors-container mx-5 my-5">
    <div class="venue-heading">Frequently Asked Questions</div>
    <div class="factors-p">
      <app-custom-collapse class="d-flex flex-column gap-2" [faq]="faq"></app-custom-collapse>
    </div>
  </div> -->
  <!-- <div class="factors-container mx-5">
      <div class="venue-heading">Wedding Venues by zone</div>
      <div class="mt-4">
        <ul class="list-unstyled zone-list">
          <li
            class="d-flex justify-content-between align-items-center"
            *ngFor="let zone of zoneList"
          >
            <span class="custom-text-hover"
              ><i class="mr-5 fas fa-arrow-right"></i>{{ zone.name }}</span
            >
            <span>{{ zone.count }}</span>
          </li>
        </ul>
      </div>
    </div> -->

  <div class="factors-container mx-5 my-5">
    <div class="venue-heading">All type of wedding venues</div>
    <div>
      <ul class="list-unstyled all-type-venues">
        <li>
          <i class="mr-5 custom-text-color fas fa-arrow-right"></i> Banquet
          Halls
        </li>
        <li>
          <i class="mr-5 custom-text-color fas fa-arrow-right"></i> Hotels
        </li>
        <li>
          <i class="mr-5 custom-text-color fas fa-arrow-right"></i> Marriage
          Garden
        </li>
        <li>
          <i class="mr-5 custom-text-color fas fa-arrow-right"></i> Kalyana
          Mandapams
        </li>
        <li>
          <i class="mr-5 custom-text-color fas fa-arrow-right"></i> Wedding
          Resorts
        </li>
        <li>
          <i class="mr-5 custom-text-color fas fa-arrow-right"></i> Wedding
          Lawns Farmhouses
        </li>
      </ul>
    </div>
  </div>
</div>
