<div class="container-fluid mt-4">
  <div class="row">
    <!-- Bootstrap col-lg-2 (instead of col-lg-3) for the left sidebar to reduce its width -->
    <!-- Left Side - Sidebar -->
    <div class="col-lg-2 col-md-3 col-sm-12">
      <a class="navbar-brand" routerLink="/home" href="#">
        <img
          loading="lazy"
          src="/assets/images/logo_desktop.png"
          alt="Logo"
          height="55"
          class="d-inline-block align-text-top"
        />
      </a>

      <div
        *ngFor="let item of status"
        class="my-30 d-flex align-items-center cursor-hover-red"
        (click)="showContent(item.title)"
      >
        <!-- <div [style.background-color]="item.color" class="rounded-circle me-3 status-bullet"></div> -->
        <div
          class="d-flex align-items-center"
          [routerLink]="item.route"
          routerLinkActive="custom-text-color"
        >
          <mat-icon class="me-3 iconcolor">{{
            getIconForTitle(item.title)
          }}</mat-icon>
          <div class="flex-grow-1 status-item">
            {{ item.title }}
          </div>
        </div>
      </div>

      <hr />
    </div>

    <!-- Right Side - Main Content Area innerchecklist part -->
    <div class="col-lg-10 col-md-9 col-sm-12 pt-lg-0 pt-md-4">
      <router-outlet></router-outlet>
    </div>
  </div>
</div>

<!-- add task model  -->
