<!-- <div class="row m-0 p-0 w-100 justify-content-evenly">
  <div class="col-4 position-relative">
    <img loading="lazy" class="flowers" src="https://weddingaro.s3.ap-south-1.amazonaws.com/side-images/flowers-signup.png" alt="" srcset="">
    <div class="signup-banner position-absolute text-center"  >
      <div class="sign-text fs-4 m-auto">
        Plan your Wedding
        hassle free
      </div>
      <div class="mt-2">
        with Weddingaro
      </div>
    </div>
  </div>
  <div class="col-4 ">
    <div class="w-100 text-center custom-text-color">
      <div class="w-100 text-center custom-text-color">
        <img loading="lazy" src="/assets/images/logo.png" height="100" alt="" srcset="">
      </div>
    </div>
    <div class="w-100 text-center fs-3 font-weight-light mt-4" >
     {{ step==1? "Forgot password? Don’t worry we have your back" : "Create New Password" }}
    </div>



    <div class="signup-form mt-4">
        <form class="example-form " [formGroup]="emailForm" *ngIf="step==1">
          <div class="row w-100">
          </div>
          <div class="row w-100">
            <mat-form-field class="example-full-width" >
              <mat-label>Email</mat-label>
              <input type="email" matInput formControlName="email" >
              <ng-container
                *ngIf="this.emailForm.get('email')?.invalid && this.emailForm.get('email')?.touched ">
                <mat-error *ngIf="this.emailForm.get('email')?.errors?.email">
                  Please enter a valid email address
                </mat-error>
                <mat-error *ngIf="this.emailForm.get('email')?.errors?.required">
                  Email is required
                </mat-error>

              </ng-container>
            </mat-form-field>
          </div>
        </form>

        <form [formGroup]="resetPasswordForm" *ngIf="step==2">
          <div class="row w-100">
          </div>
          <div class="row w-100">
            <mat-form-field class="example-full-width" >
              <mat-label>New Password</mat-label>
              <input type="password" matInput formControlName="password">
              <ng-container
                *ngIf="this.resetPasswordForm.get('password')?.invalid && this.resetPasswordForm.get('password')?.touched  ">
                <mat-error *ngIf="this.resetPasswordForm.get('password')?.errors?.minlength">
                 Password cannot be less than 6 characters.
                </mat-error>
                <mat-error *ngIf="this.resetPasswordForm.get('password')?.errors?.required">
                  Password is required
                </mat-error>

              </ng-container>
            </mat-form-field>
            <mat-form-field class="example-full-width" >
              <mat-label>Confirm New Password</mat-label>
              <input type="password" matInput formControlName="confirmpassword">
              <ng-container *ngIf="this.resetPasswordForm.get('confirmpassword')?.invalid && this.resetPasswordForm.get('confirmpassword')?.touched || passwordDonotMatch">
                <mat-error *ngIf="this.resetPasswordForm.get('confirmpassword')?.errors?.required">
                  Confirm Password is required
                </mat-error>
                <mat-error *ngIf="passwordDonotMatch">
                  Passwords Do Not Match.
                </mat-error>

              </ng-container>
            </mat-form-field>
          </div>
        </form>


      <div class="w-100 text-center">
        <button class="btn custom-button text-white w-100" (click)="step==1 ? resetPassword(): submitPasswords()"  >
           {{step==1 ? "Reset Password" : "Save"}}
        </button>
      </div>

      <div class="d-flex justify-content-around fs-12 mt-5 text-secondary mt-5">
        <div>
          Contact Us
        </div>
        <div>
          Terms & Privacy
        </div>
        <div>
          About Us
        </div>
      </div>
      <div class="text-center fs-12 text-secondary mt-2">
        Weddingaro.com
      </div>

    </div>
  </div>
</div> -->

<div class="formMainContainer">
  <div class="imageContainer">
    <div class="signup-banner">
      <div class="sign-text fs-4 m-auto">Plan your Wedding hassle free</div>
      <div class="mt-2">with Weddingaro</div>
    </div>
    <img
      loading="lazy"
      class="flowers"
      src="https://weddingaro.s3.ap-south-1.amazonaws.com/side-images/flowers-signup.png"
    />
  </div>
  <div class="formContainer">
    <div class="w-100 text-center custom-text-color">
      <div class="w-100 text-center custom-text-color">
        <img
          loading="lazy"
          src="/assets/images/logo_desktop.png"
          routerLink="/home"
          alt="Logo"
          height="75"
          class="d-inline-block align-text-top"
        />
      </div>
    </div>
    <div class="w-100 text-center fs-3 font-weight-light mt-4">
      {{
        step == 1
          ? "Forgot password? Don’t worry we have your back"
          : "Create New Password"
      }}
    </div>

    <div class="signup-form mt-4">
      <form class="example-form" [formGroup]="emailForm" *ngIf="step == 1">
        <div class="row w-100"></div>
        <div class="row w-100">
          <mat-form-field class="example-full-width">
            <mat-label>Email</mat-label>
            <input type="email" matInput formControlName="email" />
            <ng-container
              *ngIf="
                this.emailForm.get('email')?.invalid &&
                this.emailForm.get('email')?.touched
              "
            >
              <mat-error *ngIf="this.emailForm.get('email')?.errors?.email">
                Please enter a valid email address
              </mat-error>
              <mat-error *ngIf="this.emailForm.get('email')?.errors?.required">
                Email is required
              </mat-error>
            </ng-container>
          </mat-form-field>
        </div>
      </form>

      <form [formGroup]="resetPasswordForm" *ngIf="step == 2">
        <div class="row w-100"></div>
        <div class="row w-100">
          <mat-form-field class="example-full-width">
            <mat-label>New Password</mat-label>
            <input type="password" matInput formControlName="password" />
            <ng-container
              *ngIf="
                this.resetPasswordForm.get('password')?.invalid &&
                this.resetPasswordForm.get('password')?.touched
              "
            >
              <mat-error
                *ngIf="
                  this.resetPasswordForm.get('password')?.errors?.minlength
                "
              >
                Password cannot be less than 6 characters.
              </mat-error>
              <mat-error
                *ngIf="this.resetPasswordForm.get('password')?.errors?.required"
              >
                Password is required
              </mat-error>
            </ng-container>
          </mat-form-field>
          <mat-form-field class="example-full-width">
            <mat-label>Confirm New Password</mat-label>
            <input type="password" matInput formControlName="confirmpassword" />
            <ng-container
              *ngIf="
                (this.resetPasswordForm.get('confirmpassword')?.invalid &&
                  this.resetPasswordForm.get('confirmpassword')?.touched) ||
                passwordDonotMatch
              "
            >
              <mat-error
                *ngIf="
                  this.resetPasswordForm.get('confirmpassword')?.errors
                    ?.required
                "
              >
                Confirm Password is required
              </mat-error>
              <mat-error *ngIf="passwordDonotMatch">
                Passwords Do Not Match.
              </mat-error>
            </ng-container>
          </mat-form-field>
        </div>
      </form>

      <div class="fs-14 text-center mt-4 text-secondary">
        <button
          class="btn custom-button text-white w-100"
          (click)="step == 1 ? resetPassword() : submitPasswords()"
        >
          {{ step == 1 ? "Reset Password" : "Save" }}
        </button>
      </div>

      <div class="d-flex justify-content-around fs-12 mt-5 text-secondary mt-3">
        <div routerLink="/contact-us" class="cursor-hover-red">Contact Us</div>
        <div routerLink="/terms-and-condition" class="cursor-hover-red">
          Terms & Condition
        </div>
        <div  routerLink="/privacy-and-policy" class="cursor-hover-red">Privacy & Policy</div>
        <div routerLink="/about-us" class="cursor-hover-red">About Us</div>
      </div>
      <div
        [routerLink]="'/'"
        class="text-center fs-12 text-secondary mt-2 cursor-hover-red"
      >
        Weddingaro.com
      </div>
    </div>
  </div>
</div>
