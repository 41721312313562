<div class="search-outer-div " style=" box-shadow: 10px 10px 20px rgba(0, 0, 0, 0.5);">
  <div class="inner-div custom-background ">
    <div>
      <input value="{{ selectedFilter }}" (blur)="blurVenueDropdown()" (focus)="toggleVenueDropdown()"
        [placeholder]="selectedFilter || 'Enter Category'" class="form-text-input form-control" readonly />
    </div>
    <div>
      <input value="{{ selectedCity }}" (blur)="blurCityDropdown()" (focus)="toggleCityDropdown()"
        [placeholder]="selectedCity || 'Enter City'" class="form-text-input form-control" readonly />
    </div>
    <div style="width: 50% !important;">
      <button type="button " class="search-button cursor-hover-light-red text-center" (click)="search()">Search</button>
    </div>
  </div>

  <div class="d-flex select-options" *ngIf="showVenue">
    <ng-container *ngFor="let dropitem of this.filters['category']; let i = index">
      <div *ngIf="i % 12 === 0">
        <div class="" *ngFor="let venue of this.filters['category'].slice(i, i + 12)" (click)="selectVenue(venue)">
          <!-- <i class="fa-solid fa-arrow-right custom-text-color" style="width: 16px"></i> -->
          <div class="custom-text-hover m-2">
            {{ venue.name }}
          </div>
        </div>
      </div>
    </ng-container>

  </div>

  <div class="select-options" *ngIf="showCity">
    <div class="" (click)="selectCity('')">
      <!-- <div class="custom-text-hover m-2">{{ 'All' }}</div> -->
      <ng-container *ngFor="let cityitem of cityOptions; let i = index">
        <div *ngIf="i % 4 === 0" class="row">
          <div class="col-12 col-sm-6 col-md-4 col-lg-3 " *ngFor="let city of cityOptions.slice(i, i + 4)"
            (click)="selectCity(city);$event.stopPropagation()">
            <div class=" custom-text-hover col-12 col-sm-6 col-md-4 col-lg-3 m-3">{{ city }}</div>
          </div>
        </div>
      </ng-container>
    </div>
  </div>