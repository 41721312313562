<div style="font-family: Baskervville" class="min-vh-60 p-5 contact-div">
  <h2 class="fw-600" style="font-family: Baskervville">Terms & Condition</h2>
  <div class="mt-4">
    <div>
      <h1 class="fw-600">General Terms of Use of the Weddingaro.com</h1>
      <p>
        This document is an electronic record in the form of an electronic
        contract formed under the Information Technology Act, 2000 and rules
        thereunder and the amended provisions relating to electronic documents /
        records in various statues as amended by the Information Technology Act,
        2000. These terms do not require any physical, electronic, or digital
        signature. This document is published in accordance with the provisions
        of Rule 3(1) of the Information Technology (Intermediaries guidelines)
        Rules, 2011 that require publishing the rules and regulations, privacy
        policy and Terms of Use for access or usage of the www.Weddingaro.com
        Web site.
      </p>
    </div>
    <ol class="ol-box">
      <li>
        <h3 class="fw-600">1. LEGAL INFORMATION</h3>
        <ul>
          <li>Trifecta Net Private Limited</li>
          <li>Operator of the Weddingaro.com Website</li>
          <li>37 Siris Road, DLF Phase III, Gurugram-122002, Haryana, India</li>
          <li>Telephone: 9999575044</li>
          <li>Email: hello@Weddingaro.com</li>
        </ul>
      </li>
      <li>
        <h3 class="fw-600">
          2. GENERAL TERMS OF USE OF THE Weddingaro.com WEBSITE AND USER INFORM
        </h3>
        <ol class="ol-box">
          <li>
            <h4 class="fw-600">2.1 Preamble</h4>
            <p>
              Trifecta Net Private Limited (hereinafter "Weddingaro.com") makes
              the Website Weddingaro.com (hereinafter the "Website") available
              to Internet users.
              <br />
              <br />
              The ones who use and access the Website will be automatically
              considered as Users. Therefore, both physical persons who use the
              Website as end user and legal persons who use it as part of their
              professional activities in relation to the services Weddingaro.com
              offers to the professionals of the weddings sector.
              <br />
              <br />
              Access to and use of the Website imply full and unreserved
              acceptance by Users of the content of the "General Terms of Use",
              the "Personal Data Protection Policy" as well as, if necessary,
              the "Particular Conditions" that may complement, substitute or
              modify them in any way in relation to the services and contents of
              the Website. The User may access, print, download and save the
              General Terms of Use at any time. These conditions will be
              permanently accessible on the Website through the link "Legal
              Condition".
              <br />
              <br />
              Weddingaro.com reserves the right to modify, without any prior
              notice, the content of the Legal Conditions and therefore
              recommends that Users thoroughly read the texts contained therein
              before accessing and using any services of the Website.
              <br />
              <br />
              Moreover, Weddingaro.com reserves the right to modify, without any
              prior notice, the design, presentation and/or configuration of
              this Website, as well as any or all of its services or features,
              being able to remove or add them at their judgment.
              <br />
              <br />
              In any case, Weddingaro.com reserves the right, at any time and
              without any prior notice, to deny access to the Website to users
              who violate any of these General Terms of Use or any applicable
              Particular Conditions, or who, according to the unilateral
              criterion of Weddingaro.com, do not use the Website properly.
            </p>
          </li>
          <li>
            <h4 class="fw-600">2.2 Object</h4>
            <p>
              The present General Terms of Use govern the access and use of the
              Website, which aims to be a reference point for those couples in
              the process of planning their wedding and for the providers of
              services associated with this type of event.
              <br />
              <br />
              In this regard, Weddingaro.com puts providers and couples in touch
              through the Website, placing at their disposal interaction tools,
              offering providers an objective public interested in the products
              and services they provide, and offering couples the providers of
              products and services related to planning and organizing a
              wedding.
              <br />
              <br />
              Weddingaro.com will only provide other users with the data and/or
              information provided by the User when this data and/or information
              does not violate legal regulations or the present General Terms of
              Use.
              <br />
              <br />
              Weddingaro.com may, without prior notice, delete contents from the
              Website contrary to enforceable law or which would or could
              violate the rights of third parties, as well as contents that may
              be considered inappropriate or inadequate or that fail to meet the
              minimal desired quality standards.
              <br />
              <br />
              The User acknowledges that it is not technically possible to
              achieve 100% Website availability. However, Weddingaro.com will
              endavour to maintain the Website available as constant as
              possible. In particular for maintenance, security or capacity
              reasons, as well as a consequence of events over which
              Weddingaro.com has no control, (for example, failures in public
              communication networks, power failures, etc.), may cause brief
              anomalies or temporary suspension of the Services of the Website.
            </p>
          </li>
          <li>
            <h4 class="fw-600">2.3 Obligations of the Website Users</h4>
            <div>
              The User agrees to make diligent use of the Website and the
              services accessible through it, in full compliance with the Law,
              good manners and these General Terms of Use, and, if necessary,
              with any applicable particular conditions and with due respect to
              other users. In accordance with the terms of paragraph four,
              section 2.2, above, Weddingaro.com reserves the right to remove
              any content without any notice that, in its discretion, does not
              meet the minimum quality requirements.
              <br />
              <br />
              Users who access the Website as professionals of the sector and
              who use the website to offer their professional services
              explicitly undertakes to comply strictly with all and any
              regulations directly or indirectly affecting its activity and the
              professional development thereof, explicitly exempting
              Weddingaro.com of any possible liability arising from the Client's
              failure to perform any of its obligations. In this regard,
              Weddingaro.com may carry out the necessary investigations and
              request the documentation it deems appropriate to verify
              compliance with these obligations. Weddingaro.com reserves the
              right to deny access to the Website to those users who do not
              provide the requested documentation or who deliberately hide it
              and fail to comply with the applicable regulations.
              <br />
              <br />
              In general, the provision of services does not require the prior
              registration or subscription of the users. However, Weddingaro.com
              conditions the use of some of the services to the prior completion
              of the appropriate User Registration Form (company or individual),
              choosing the ID and password that the User agrees to keep and use
              with the due diligence.
              <br />
              <br />
              The use of the password is personal and non-transferable, the
              transfer, even temporary, to third parties is not allowed. In this
              regard, the User must take the necessary measures for the custody
              of the chosen password preventing third parties from using it.
              Consequently, the User is solely responsible for the use of their
              password and Weddingaro.com is fully indemnified. In the event
              that the User knows or suspects that their password has been used
              by third parties, they must bring this to the attention of
              Weddingaro.com as soon as possible.
              <br />
              <br />
              All the information provided by the User, via any of the available
              methods, in order to receive the services provided by
              Weddingaro.com must be truthful and accurate. For this purpose,
              the User guarantees the truthfulness of the data they have
              provided by completing forms or answering requests made by
              Weddingaro.com, which are necessary for the subscription of the
              Services.
              <br />
              <br />
              Moreover, it shall be responsibility of the User to maintain all
              information provided to Weddingaro.com constantly up to date and
              adapted in each moment to the actual situation of the User. In any
              case, the User shall be solely responsible of any false or
              inaccurate statements it makes and the damages those may cause to
              Weddingaro.com or third parties.
              <br />
              <br />
              The User agrees to respect the applicable laws and the rights of
              third parties when using the contents and services of the Website.
              Moreover, the reproduction, distribution, transmission, adaptation
              or modification, by any means and in any form, of the contents of
              the Website, (texts, designs, graphics, information, databases,
              sound files and/or images, logos, etc.), and other elements of
              this Website, is expressly forbidden without prior authorization
              by its legitimate owners or when permitted by Law.
              <br />
              <br />
              In particular, the User is forbidden from: using libelous or
              slanderous contents, regardless of whether that content affects
              other users or individuals or companies, using pornographic
              content or contents that violate the laws on the protection of
              minors, advertising, offering or distributing pornographic
              products or products that violate the laws on the protection of
              minors, disturbing other users, (especially through spam), using
              legally protected contents (e.g. by laws on intellectual property,
              trademarks, patents, utility models or aesthetic models) without
              being entitled to do so or advertise or offer or distribute
              legally protected goods or services, as well as perform or promote
              actions contrary to free competition, including those aimed at
              progressive customer acquisition (as a chain system, snowball or
              pyramid scheme). Moreover, Users are prohibited from promoting
              goods and services that lead to their own benefit or that of
              persons related to them.
              <br />
              <br />
              The User is prohibited from performing, by way of example but not
              limitation, the following actions:
              <ul>
                <li>
                  Use mechanisms, software or scripts in relation to the use of
                  the Website.
                </li>
                <li>
                  Block, overwrite, modify or copy, unless necessary for the
                  proper use of the services of the Website. For example,
                  copying using search engine technologies such as
                  "Robot/Crawler" is not necessary for the proper use of the
                  Website services and is therefore expressly forbidden.
                </li>
                <li>
                  Spread and publicly reproduce contents of the Website or of
                  other users, without prior authorization.
                </li>
                <li>
                  Any action aimed at jeopardizing the functionality of the
                  Weddingaro.com infrastructure, especially overloading it.
                </li>
                <li>
                  Use any of the materials and information contained in this
                  Website for illegal purposes and/or purposes expressly
                  prohibited by these General Terms of Use as well as by any
                  applicable particular conditions and/or purposes contrary to
                  the rights and interests of Weddingaro.com, its members and/or
                  third parties. The User must answer to the injured parties if
                  by contravening or breaching said obligations in any way
                  (including by introducing or spreading "computer viruses")
                  damages, disables, overloads, impairs or impedes the normal
                  use of the materials and information contained in the Website,
                  the information systems or documents, files and any other
                  content stored on any computer (hacking) of Weddingaro.com,
                  its members or any user.
                </li>
                <li>
                  Insert comments or Contents outside the object and purpose of
                  the Website.
                </li>
                <li>
                  Insertion of contact information in fields other than those
                  that exclusively request such information. The Website
                  reserves the right to edit any content that contravenes this
                  obligation.
                </li>
              </ul>
              <br />
              <br />
              The user is aware and voluntarily accepts that the use of the
              Website and the services provided through it occurs in all cases
              under his sole responsibility.
              <br />
              <br />
              Users are liable for damages of any kind that Weddingaro.com or
              any third party may suffer as a result of the breach of any of the
              obligations to set forth herein or by the law in relation to using
              the Service.
              <br />
              <br />
              Weddingaro.com shall not mediate in conflicts between users or
              between users and third parties that may interfere with the proper
              provision of its services and reserves the right to remove them
              from the Web Site or to cancel any existing contract, where
              applicable, until any dispute or disagreement has been completely
              resolved.
            </div>
          </li>
          <li>
            <h4 class="fw-600">
              2.4 Obligations related to the insertion of content into the
              Website
            </h4>
            <div>
              By including information, text, audio, video and/or images
              /visuals on the Web Site (hereinafter the "Content"), the User
              declares to be the legitimate holder of the intellectual property
              rights of such Content for the reproduction, distribution and
              public communication thereof by any means or through any form of
              support, extending internationally for everyone and with complete
              legal validity with the intention of protect such rights.
              <br />
              <br />
              In this regard, the User declares to have sufficient rights for
              the insertion of the Content on the Website and expressly exempts
              Weddingaro.com for any claim that may arise for it from this
              circumstance. Users are solely responsible for obtaining any
              authorization, permit or license required for the completion and
              publication of the Content.
              <br />
              <br />
              Weddingaro.com does not allow the inclusion of content that, in
              its opinion, reduces the quality of service. It is prohibited to
              include contents that:
              <br />
              <br />
              <ul>
                <li>
                  Are allegedly unlawful under national, EU or international
                  regulations or is allegedly conducting illegal activities or
                  contradict the principles of good faith.
                </li>
                <li>
                  Do not meet the minimum quality standards established in
                  Weddingaro.com for the presence on the Website.
                </li>
                <li>
                  Violate the fundamental rights of individuals, search for the
                  weaknesses of the user, show bad online manners, disturb
                  and/or can or seek to generate negative opinions in users or
                  third parties.
                </li>
                <li>
                  Contradict the principles of legality, honor, responsibility,
                  protection of human dignity, protection of minors, protection
                  of public order, protection of privacy, consumer protection
                  and intellectual property rights.
                </li>
              </ul>
              <br />
              <br />
              Weddingaro.com reserves the right to remove any Website content it
              deems inappropriate for the character and purpose of the website
              and users without any prior notice. It also reserves the right to
              modify and / or adapt content, if necessary without any prior
              notice, without thus affecting the integrity of the contribution,
              in order to adapt it to the formal requirements and the quality of
              the Website or the media on which such content may appear,
              according to the provisions of the General Terms.
              <br />
              <br />
              Although Weddingaro.com uses media to try to ensure that the
              contents do not violate the foregoing prohibitions, and that they
              meet the quality parameters of Weddingaro.com and the rules
              contained herein, it is not able to control each and every one of
              the published contents and is therefore unable to assume
              responsibility for them.
              <br />
              <br />
              If a user notices any inappropriate content on the website, they
              may contact Weddingaro.com through our Customer Care service: info
              [at] Weddingaro.com
              <br />
              <br />
              With regard to contents involving minors, Weddingaro.com expresses
              its willingness to promote the proper use of Internet. It also
              declares its desire to avoid the practice, the dissemination of
              messages or images of illegal nature or such that may or cause
              harm to human dignity, the safety or the values of protection of
              children and youth.
              <br />
              <br />
              The website acknowledges, respects and protects the rights of
              minors in the context of protection of youth and children both
              nationally and internationally.
              <br />
              <br />
              In accordance with the provisions of the regulations in force, it
              is considered to be an illegitimate interference in the right to
              honor, personal and family privacy and selfimage of the child, any
              use of their image or name in the media that may cause damage to
              the child's honor or reputation, or that is contrary to their
              interests even if the child or their legal representatives have
              given their consent.
              <br />
              <br />
              In this regard, any user who includes on the Web Site Content able
              to infringe the rights set forth in the preceding paragraph shall
              be immediately removed and their behavior reported to the relevant
              government agencies.
              <br />
              <br />
              For this purpose, the users are reminded that pursuant to the
              regulations in force, anyone who uses a minor for the purposes of
              or in exhibitionist or pornographic performances is punishable by
              imprisonment of one to three years.
              <br />
              <br />
              Notwithstanding the foregoing, the inclusion on the Website of
              personal data of minors and, in particular, their image, implies
              the explicit and unreserved statement of the User who made the
              insertion that:
              <br />
              <br />
              <ul>
                <li>
                  They own the rights to use the image or data of the minor.
                </li>
                <li>
                  Insertion of the said data does not harm the minor in any way
                  nor violates any of their rights.
                </li>
                <li>
                  Weddingaro.com is expressly exempted from any possible claim
                  arising out of this, assuming the user who inserted the
                  information full liability with regard to such claim.
                </li>
              </ul>
              <br />
              <br />
              Any Content provided by users will become part of the composite
              work that is the Web site as an object of intellectual property
              rights, whose sole shareholder is Weddingaro.com. In this regard,
              the User who contributes any Content expressly states that they
              give freely and free of charge a license to the Content provided
              to Weddingaro.com and any intellectual property rights on it and
              in particular the rights of reproduction, transformation and
              public communication for everybody and during the legal period of
              protection thereof.
            </div>
          </li>
          <li>
            <h4 class="fw-600">
              2.5 Responsibility and distribution of content, data and / or user
              information
            </h4>
            <p>
              Weddingaro.com can use and spread the content that users have
              contributed to the Website and, in particular, will be able to
              broadcast them in any of the websites or social networks in which
              Weddingaro.com is present.
              <br />
              <br />
              For this reason, Weddingaro.com may modify or adapt the content if
              necessary, in order to respect the graphic design of the Website
              or other media of communication used on the Weddingaro.com
              platform without such modification or adaptation be respectful of
              the integrity of the provided content. These rights or licenses of
              use are granted for everybody and during the legal period of
              protection of the rights of the user.
              <br />
              <br />
              Weddingaro.com has no obligation to monitor the use made by Users
              of the services provided by it and therefore cannot guarantee that
              users use them in accordance with the provisions in these Terms,
              or use them diligently or prudently.
              <br />
              <br />
              Similarly, Weddingaro.com has no obligation to verify the identity
              of the users or the accuracy, validity, completeness and/or
              authenticity of the data that users provide about themselves, as
              the latter assume all responsibility with regard to providing
              Weddingaro.com with real information that corresponds to the
              actual situation of the User who provided it, as Weddingaro.com
              informs herewith.
              <br />
              <br />
              Notwithstanding the foregoing, Weddingaro.com reserves the power
              to restrict, totally or partially, the access of certain users,
              and to cancel, suspend, block, edit, or delete certain type of
              content, or cancel the accounts of users who misuse the Website or
              if Weddingaro.com acquires actual knowledge that the activity or
              stored information is unlawful or harms property or rights of
              another, using suitable technological tools for this purpose,. In
              this regard, Weddingaro.com may set up the necessary filters to
              prevent that illegal or harmful content or content used for
              purposes contrary to the purposes of the website spills into the
              network through the service.
              <br />
              <br />
              Users may submit suggestions or proposals for the improvement of
              various information, activities and / or content and / or services
              enabled on the Web site, contact the Wedding Customer Care service
              by clicking here. Any new feature developed on the basis of a
              suggestion from a User shall be fully owned by Weddingaro.com and
              the User shall hold no rights over it.
            </p>
          </li>
          <li>
            <h4 class="fw-600">2.6 Disclaimer of warranties and liability</h4>
            <p>
              Except where expressly described in the Terms of Use and the rest
              of the regulatory framework of the Website, Weddingaro.com shall
              not be liable for damages of any kind caused by the lack of
              accuracy, completeness, timeliness or errors or omissions that may
              be affecting the information or the services contained on this
              website or other content that can be accessed through it, nor does
              it assume any obligation or commitment to verify or monitor the
              content and information.
              <br />
              <br />
              Similarly, despite putting the technical means at their disposal,
              Weddingaro.com does not guarantee the availability, continuity or
              infallibility of the operation of the Website, and therefore
              excludes, to the maximum extent permitted by law, any liability
              for damages of any kind that may be due to lack of availability or
              continuity of the Website and the services enabled therein, as
              well as errors in accessing the different web pages or those from
              which the services are provided.
              <br />
              <br />
              Weddingaro.com shall not be liable for the contents, data and / or
              information provided by users of the website, nor for the contents
              of external websites to which it links. In particular,
              Weddingaro.com does not guarantee that these contents are true,
              meet a specified purpose or serve that purpose.
              <br />
              <br />
              Moreover, Weddingaro.com shall not be responsible for the opinions
              expressed by users using the portal, forums, communities, or other
              tools of participation or opinion. Without limiting the foregoing,
              Weddingaro.com shall use the means at its disposal to moderate as
              much as possible the opinions expressed by users on the Website,
              in order for them to not violate the rights of third parties.
              Finally, Weddingaro.com assumes no liability of any kind in
              relation to the success of possible transactions conducted between
              users.
            </p>
          </li>
          <li>
            <h4 class="fw-600">2.7 Links to third party websites</h4>
            <p>
              Weddingaro.com includes within its contents links to sites owned
              and / or managed by third parties in order to facilitate access to
              information available through Internet. These links may be linked
              or not indicated web sites.
              <br />
              <br />
              Weddingaro.com assumes no liability for the existence of links
              between the content of this site and contents located outside the
              site or any other reference to contents external to this site.
              Such links or references are for information purposes only and in
              no way imply support, approval, commercialization or any
              relationship between Weddingaro.com and the persons or entities
              and / or managers of such contents or sites where the information
              is found.
            </p>
          </li>
          <li>
            <h4 class="fw-600">2.8 Law and Jurisdiction</h4>
            <p>
              The General Terms of Use and other legal conditions of the website
              are governed by Indian law.
              <br />
              <br />
              For any questions which may arise or actions arising from the
              provision of services and contents by the Website and the
              interpretation, application, fulfillment or breach of the
              provisions herein, Weddingaro.com and the User, expressly waiving
              any other jurisdiction that may apply, submit to the jurisdiction
              of the Courts of the state of Haryana.
            </p>
          </li>
        </ol>
      </li>
      <li>
        <h3 class="fw-600">3. TERMS OF USE OF THE COMMUNITY SERVICE.</h3>
        <p>
          The use of the community service implies that users accept the General
          Terms of Use of Community Service and other provisions contained in
          the legal conditions of the website.
        </p>
        <ol class="ol-box">
          <li>
            <h4 class="fw-600">
              3.1 Terms of Use of the Community Service and their acceptance
            </h4>
            <p>
              These Terms of Use of the Community Service govern the provision
              of the community service of the Website by Weddingaro.com and the
              conditions of use of the service by its users.
              <br />
              <br />
              Weddingaro.com offers its Users the Community service so that
              couples in the process of planning their wedding can exchange
              views and comments on all services associated with the
              celebration. In no case does Weddingaro.com allow the use of the
              Community in particular and the Web site in general as a platform
              or promotional support for professionals, particularly when these
              professionals are personally linked to any user and it is the user
              who was promoting.
              <br />
              <br />
              It is also prohibited to use the Community for mass contacts
              between users. Weddingaro.com has the technical means to detect
              and remove, if necessary, such mass mails.
              <br />
              <br />
              The use of this service by users is conditioned upon the
              completion of the registration form within the Community and the
              prior acceptance of the legal conditions of the website, as
              published by Weddingaro.com, at the very moment in which the user
              accesses the service.
              <br />
              <br />
              The use of the Community is also subject to all notices, use
              regulations and instructions made available to the User by
              Weddingaro.com.
              <br />
              <br />
              The use of the Community Service is limited to Users of the
              Website who are active members in it, using it for planning and
              managing a personal event, and professionals in the wedding
              industry who have a file on the Web Site. Notwithstanding the
              above, the main purpose is to facilitate the couple organizing an
              event, and not to serve as a platform or promotional support to
              professionals in the wedding industry. For this reason,
              Weddingaro.com reserves the right to restrict or terminate access
              to the Community to those users who make improper use of it.
              <br />
              <br />
              Weddingaro.com reserves the right to modify at any time these
              Terms of Use of the Community. They shall apply from the time they
              are made available to users through the Website. Weddingaro.com
              encourages its members to view the conditions each time they use
              the Forum service as they may change.
              <br />
              <br />
              If the user decides not to accept the present legal conditions,
              and / or any future conditions and notices that may be forwarded
              by Weddingaro.com, must refrain from accessing and / or using the
              Forum Service offered.
              <br />
              <br />
              The User that participates in the Community accepts that all
              content that they publish will be known to the rest of the
              participants in the aforementioned Community.
              <br />
              <br />
              All contents provided by the User to Weddingaro.com through
              participation in the community service shall be added to the Web
              site, being intended the latter as an object of protection of
              intellectual or similar property rights. In this sense, the User
              grants to Weddingaro.com for free the use of all the contents
              provided by participating, which will be incorporated in the
              Website, in accordance with the provisions of Clause 2.4 above.
            </p>
          </li>
          <li>
            <h4 class="fw-600">3.2 Purpose and Description of the Service</h4>
            <p>
              The purpose of these conditions is the provision of the Community
              service through the Web Site. Users, for free and after validation
              of the previously assigned username and password, can interchange,
              access, respond to messages and, in general, talk with other
              service users in order to express opinions and exchange views
              about information, activities and / or services that are already
              included in the Web Site or which can be accessed from it, as well
              as related issues.
            </p>
          </li>
          <li>
            <h4 class="fw-600">3.3 Access to the Community Service</h4>
            <p>
              The service is accessed using a user name and a password or a
              transferable personal key chosen by the user. The user assumes the
              consequences for the breach of confidentiality and misuse of keys
              and access codes.
              <br />
              <br />
              The service can be accessed freely by people over 18 years of age.
              Access to minors is restricted.
            </p>
          </li>
          <li>
            <h4 class="fw-600">3.4 Obligations of the Users</h4>
            <div>
              The User agrees to use the service in accordance with Community
              law, these Terms of Use of the Community Service, the legal
              conditions of the website, as well as morality and generally
              accepted good customs and public order. The User agrees not to use
              the service for illegal purposes, contrary to the provisions of
              these Legal Terms and Conditions of the Website, harmful to the
              rights and interests of others, or in any way able to damage,
              disable, overload or deteriorate the service or prevent the normal
              use or enjoyment of the service by other users. In particular, by
              way of example and not limitation, it is hereby prohibited to
              transmit, distribute or make available to third parties, through
              the service, any information, messages, graphics, sound files and
              / or images, photographs, recordings, software and, in general,
              any kind of material, data or content that:
              <div>
                A. In any way harass, stalk, threaten or abuse other members of
                the Community or any third parties external to it.
              </div>
              <br />
              <div>
                B. Incorporate obscene or objectionable content or such that may
                offend the sensibilities of any third parties who may access
                them.
              </div>
              <br />
              <div>
                C. Disseminate contents that may infringe or violate the rights
                of users or third parties, such as defamatory content or content
                that infringes any intellectual or
              </div>
              <br />
              industrial property rights.
              <div>
                D. Encourage others to violate these conditions, the General
                Terms of Use of the Website, and other Legal Conditions
                contained in the Website.
              </div>
              <br />
              <div>
                E. Are published with the intent to impersonate team members of
                Weddingaro.com or another user using similar identification or
                any other method or device.
              </div>
              <br />
              <div>
                F. Are used for commercial, advertising or unlawful purposes; as
                well as for the transmission of junk mail, spam, chain letters
                or unsolicited mass distribution of messages.
              </div>
              <br />
              <div>G. Infringe regulations on privacy of communications.</div>
              <br />
              <div>
                H. The sale, promotion and distribution of products or services
                through the Weddingaro.com Community platform is expressly
                prohibited. Promoting professional activities related directly
                to a user by that user or relatives is also prohibited.
              </div>
              <br />
              <div>
                On the other hand, Weddingaro.com reserves the right to remove
                and / or suspend any user services for breaching the provisions
                of the legal conditions, and to eliminate, remove and / or
                suspend any message that incorporates information with the
                characteristics listed in the letters A to H, and even if it is
                not listed there, without prior or further notice and may even
                deny access to the user who is author of the message if, at the
                discretion of Weddingaro.com, it breaches any of the objectives
                of the C ommunity.
              </div>
              <br />
            </div>
          </li>
          <li>
            <h4 class="fw-600">3.5 Content inserted by users</h4>
            <p>
              The messages and Content provided by the Users in the Community
              exclusively contain the opinions and experiences of the senders
              and their content is solely and exclusively the responsibility of
              the Users who incorporate them in the Website.
              <br />
              <br />
              The community of brides and grooms of Weddingaro.com makes the
              Dropcanvas tool available to users. The community administrators
              will share this file storage system so that the couple can attach
              documents of interest to the Community.
              <br />
              <br />
              Users undertake to make good use of this tool in accordance with
              the General Conditions of Use of the Community Service contained
              in point 3 of the General Conditions of Use of the Website.
              <br />
              <br />
              The Website is not responsible for the content of the files
              attached to Dropcanvas, so the user exempts the Website from any
              responsibility for damages and damages of any nature that could be
              derived by the use of this tool or by the Content itself of the
              shared files.
              <br />
              <br />
              The administrators of the community reserve the right to remove
              any Content that contravenes the General Conditions of Use of the
              Community Service, in the same way, are not responsible for
              content removed by users of Dropcanvas.
              <br />
              <br />
              In the event that a user of the Weddingaro.com community is found
              to misuse Dropcanvas, the Website reserves the right to restrict
              or terminate access to the Community to such a user.
            </p>
          </li>
          <li>
            <h4 class="fw-600">3.6 Disclaimer</h4>
            <p>
              Weddingaro.com is not in any way responsible for the messages sent
              by their users and the latter explicitly exonerate Weddingaro.com
              from any liability for damages of any nature arising from both the
              message content and from the knowledge that third parties may have
              regarding the conditions, characteristics and circumstances of use
              of the service made by the users or that could be due to the
              access and, where appropriate, the interception, removal,
              modification or manipulation by third parties authorized or not,
              of the messages that Users disseminate or make available to third
              parties through the community service, although Weddingaro.com
              undertakes to make available the tools necessary to prevent that
              the participation of Users in the Community does not prejudice or
              violate the rights of third parties.
            </p>
          </li>
          <li>
            <h4 class="fw-600">3.7 Personal Data</h4>
            <p>
              Weddingaro.com informs its users that it will process the personal
              data according to the terms of the Website's Policy for the
              Protection of Personal Data in accordance with regulations.
              <br />
              <br />
              Also, the user expressly allows for the opinions and content
              contributed to the Website to be found and accessed by Internet
              search engines. Notwithstanding the above, there are discussion
              groups that, due to their strictly confidential and intimate
              content, as determined by Weddingaro.com, will not be accessible
              by these search engines, and neither will the personal profile of
              users be accessible.
              <br />
              <br />
              Users guarantee the truthfulness, accuracy, validity and
              authenticity of the Personal Data provided, and undertake to keep
              them updated.
            </p>
          </li>
          <li>
            <h4 class="fw-600">3.8 No license</h4>
            <p>
              Weddingaro.com authorizes the User to use the intellectual and
              industrial property rights to the software that allows the
              execution of the services that make up the Service only as
              provided for in these Conditions.
              <br />
              <br />
              Notwithstanding the foregoing, Weddingaro.com grants no license or
              authorization of use of any kind on its intellectual property or
              any other property or right related to the Community Service or
              the Website itself or on the contents of the Website's
              Intellectual Property Rights Statement that the users can find in
              the legal conditions.
            </p>
          </li>
          <li>
            <h4 class="fw-600">

             3.9 Applicable legislation
            </h4>
            <p>
              These Terms are governed in each and every one of its clauses by
              the laws applicable in India.
            </p>
          </li>
        </ol>
      </li>
      <li>
        <h3 class="fw-600">4. INTELLECTUAL AND INDUSTRIAL PROPERTY RIGHTS</h3>

        <div>
          Copyright © 2008 - 2023 Trifecta Net PRIVATE LIMITED. - All rights All
          exploitation rights are reserved.
          <br />
          <br />
          This Web site is governed by Indian laws and is protected by national
          and international legislation on intellectual and industrial property.
          <br />
          <br />
          The texts, designs, images, databases, logos, structure, brands and
          other elements of this site are protected by laws and international
          treaties on intellectual property.
          <br />
          <br />
          Any reproduction, transmission, adaptation, translation, modification,
          public communication or any other use of all or part of the contents
          of this site, conducted in any form or by any means, electronic,
          mechanical or otherwise, is strictly prohibited without prior written
          authorization by Weddingaro.com or other owners. Any infringement of
          these rights may lead to extrajudicial or judicial civil or criminal
          proceedings.
          <br />
          <br />
          Weddingaro.com does not grant any license or authorization of use of
          any kind on its intellectual property or any other property or right
          related to the Web Site, the services or the contents.
          <br />
          <br />
          The User authorizes Weddingaro.com to use free of charge their logos,
          trademarks and Contents provided to Web Site for the provision of the
          contracted services. The user also authorizes Weddingaro.com to use
          these logos, trademarks and content free of charge in all sections of
          the Website as well as in the social networks in which Weddingaro.com
          is present, always indicating the source.
          <br />
          <br />
          The User expressly states that none of the contents provided to
          Weddingaro.com violates any industrial or intellectual property rights
          or any other rights of third parties, explicitly exonerating
          Weddingaro.com with regard to any claim relating to this matter made
          by any third party and assuming full responsibility for these acts.
          <br />
          <br />
          The legitimacy of the intellectual or industrial property rights
          corresponding to the contents provided by users is the sole
          responsibility of the latter, expressly exempting Weddingaro.com from
          any possible claims brought forward by third parties.
          <br />
          <br />
          For the purposes of protecting the intellectual property rights, in
          the event that any user or third party considers that there has been a
          violation of their legitimate rights caused by the introduction of
          certain content on the website, they should notify in writing Trifecta
          Net PRIVATE LIMITED (Weddingaro.com)
          <br />
          <br />
          <ul>
            <li>
              Personal identification information of the holder of the allegedly
              infringed rights. If the claim is submitted by someone other than
              the person concerned, they should indicate their right to
              represent the actor.
            </li>
            <li>
              The contents protected by intellectual property rights and their
              location on the web.
            </li>
            <li>
              Proof of existence, ownership and validity of said intellectual
              property rights.
            </li>
            <li>
              Explicit statement in which the interested party takes
              responsibility for the accuracy of the data and information
              provided in the notification referred to herein.
            </li>
          </ul>
        </div>
      </li>
      <li>
        <h3 class="fw-600">5. PRIVACY POLICY</h3>
        <ol>
          <li>
            <h4 class="fw-600">

             5.1 Right to information
            </h4>
            <p>
              This policy of protection of personal data governs the access and
              use of the services contained on the Website by the Users as
              owners of the data worthy of protection in accordance applicable
              law.
              <br />
              <br />
              Weddingaro.com, as the owner of the website, informs the user
              about the existence of files with personal data created by it and
              under its responsibility. Weddingaro.com complies with applicable
              legislations regarding the protection of personal data and the
              confidentiality requirements of its activity.
              <br />
              <br />
              Users who decide to use the services offered by Weddingaro.com
              through the Web site and fill in the registration form must
              provide the data considered necessary to achieve the Website's
              purpose, which to facilitate a meeting between companies that
              offer products and services related to the celebration of a
              wedding and users interested in the products and / or services.
              <br />
              <br />
              For this purpose, users are required to fill in a form and include
              the personal data necessary for the achievement of the above
              purposes within the regulatory framework. The fields of the
              registration form marked with an asterisk (*) are mandatory.
              <br />
              <br />
              In order to comply with the services provided by Weddingaro.com,
              the data provided by the user, either through the forms on the
              Website or through any other means including, without limitation,
              e-mail, postal mail, or voice calls, will be included in the file
              "Users" with personal data, which is duly registered with the Data
              Protection Agency.
              <br />
              <br />
              The User explicitly states to accept this Data Protection Policy
              in relation to all the data they provide to Weddingaro.com
              regardless of how they have been provided.
            </p>
          </li>
          <li>
            <h4 class="fw-600">

             5.2 Purpose
            </h4>
            <div>
              The data provided by the users to Weddingaro.com through any
              means, with the purpose to enable the latter to provide the
              services demanded by the former, are collected for the following
              purposes:<br />
              <ul>
                <li>
                  Provide users with a quick and easy way to search for
                  companies offering wedding-related services and products by
                  province, industry, type of service and population.
                </li>
                <li>
                  Publicize Products and services of companies related to the
                  celebration of a wedding by creating a form with their contact
                  details, location, description of services and / or products
                  and photographs, if any.
                </li>
                <li>
                  Facilitate the contact, through requests for information via
                  email and telephone, between companies that advertise their
                  products or services on the Website and users interested in
                  them.
                </li>
                <li>
                  Facilitate for the Users the creation of a personal file which
                  will be accessible by the other users of the Website.
                </li>
                <li>
                  Provide Users with all the information necessary for their
                  wedding through articles and tips posted on the Website.
                </li>
                <li>Creation and participation in the Website Community.</li>
                <li>
                  Provide a helpline for wedding planning, involving advice and
                  assistance in locating and selecting the suppliers for the
                  organization of the wedding; the service is provided through a
                  telephone contact between Weddingaro.com and the User and the
                  referral of requests for information, on behalf of the users
                  and with their express consent, to the service providers
                  suggested to the User.
                </li>
                <li>
                  Issue promotional and informative electronic communication on
                  services provided by companies that have trade agreements with
                  Weddingaro.com, associated with the wedding industry and the
                  needs or interests that the couple may have during the process
                  of organizing the event.
                </li>
                <li>
                  Maintain regular and dynamic communication with the Users
                  through any means, including social media and instant
                  messaging. In such cases, without prejudice to applying
                  Weddingaro.com's Privacy Policy, the privacy policy of the
                  corresponding social media or instant messaging service shall
                  also be applied. Our recommendation is to analyze the privacy
                  policy in detail.
                </li>
              </ul>
            </div>
          </li>
          <li>
            <h4 class="fw-600">

             5.3 Mandatory or optional nature of the information provided by the user
              and accuracy of data
            </h4>
            <p>
              Fields marked with an asterisk (*) in the registration form the
              user must fill in are strictly necessary to service their request,
              the inclusion of data in the remaining fields is voluntary.
              <br />
              <br />
              Likewise, regarding the data provided by any other means, at the
              time of processing, the User will be informed about whether
              providing them is mandatory or not.
              <br />
              <br />
              The User guarantees that the personal data provided to
              Weddingaro.com are truthful and up to date, and is responsible for
              communicating any changes thereto being the User solely
              responsible for the inaccuracy or falsity of the information
              provided and any damages thereby caused to Weddingaro.com or third
              parties for the use of the services offered by Weddingaro.com.
              Weddingaro.com also states that the data collected are adequate,
              relevant and not excessive in relation to the scope, aims and
              certain services.
            </p>
          </li>
          <li>
            <h4 class="fw-600">

             5.4 User Consent
            </h4>
            <p>
              When providing their data to Weddingaro.com through the
              registration form, the User states to have read and expressly
              accepted the legal terms of Weddingaro.com and gives unequivocal
              and express consent to the processing of personal data in
              accordance with the purposes and services Weddingaro.com provides.
              The User declares to have read and expressly accepted the legal
              conditions for Weddings and gives unequivocal and express consent
              to the processing of personal data according to the purposes and
              services provided by Weddingaro.com unlike the personal data they
              may have provided through any other mechanism different from the
              forms on the Website.
              <br />
              <br />
              In addition, the User agrees that when they register, their photos
              and user profile will be publicly visible to other users of
              Weddingaro.com well as the various search engines.
              <br />
              <br />
              When the User cancels their account, Weddingaro.com will also
              erase the profile photo and the identifying information of the
              user on the Web Site (name, last name and email address) but the
              rest of the content that the User has published will remain on the
              Web Site.
              <br />
              <br />
              The User expressly consents to the transfer of data to other users
              of Weddingaro.com and entities that use the services of
              Weddingaro.com to find users interested in them.
            </p>
          </li>
          <li>
            <h4 class="fw-600">

             5.5 Electronic Communications
            </h4>
            <p>
              The filling in and sending of the Weddingaro.com electronic form
              constitutes express consent of the User to receive a Newsletter
              that includes, updates and information relevant to the Website and
              the sectors related to it.
              <br />
              <br />
              Likewise, registering as a User in the Website constitutes
              acceptance of receiving promotional and informative electronic
              communications regarding services provided by companies with which
              Weddingaro.com has commercial agreements, associated with the
              weddings sector and the needs and interests that couples may have
              during the process of organizing the event.
              <br />
              <br />
              Weddingaro.com establishes two mechanisms by which the Users who
              so request, may modify or eliminate those services easily, quickly
              and free of charge: (i) through the user menu, by disabling the
              receive option in the "Notifications" sub-menu, contained in the
              "My Account" menu or (ii) by following the instructions contained
              at the bottom of the electronic communications.
            </p>
          </li>
          <li>
            <h4 class="fw-600">

             5.6 Security
            </h4>
            <p>
              Weddingaro.com informs the Users that it has adopted the technical
              and organizational measures established by regulation which ensure
              the security of personal data and avoid its alteration, loss,
              treatment or unauthorized access, given the state of technology,
              the nature of the data stored and the risks to which they are
              exposed, all in accordance with the applicable legislative
              requirements in force.
            </p>
          </li>
          <li>
            <h4 class="fw-600">

             5.7 Cookies and IPs
            </h4>
            <p>
              The User accepts the use of cookies and IP tracking. Our site
              traffic analyzer uses cookies and IP tracking that allow us to
              collect data for statistical purposes such as: date of first
              visit, number of times visited, date of last visit, origin URL and
              domain, browser used and screen resolution. However, the User may
              deactivate and/or delete these cookies following the instructions
              provided by their Internet browser. <br /><br />
              Weddingaro.com will not use "spamming" techniques, and will
              process only the data that the user transmits by the electronic
              form enabled on the website or electronic mail messages.
            </p>
          </li>
          <li>
            <h4 class="fw-600">

             5.8 Right to Access, Correct and Delete Data
            </h4>
            <p>
              The User has the right to access this information, correct it if
              the data is erroneous and unregister from the services of
              Weddingaro.com.
              <br />
              <br />
              These rights can be exercised using the Website's own
              configuration. In case of problems when doing so online, and for
              any type of inquiry or controversy regarding the data protection
              policy, Users may write directly to Trifecta Net PRIVATE LIMITED
              (Weddingaro.com)
              <br />
              <br />
              The processing of personal data and the sending of commercial
              communications by electronic means are in accordance with the
              applicable legislations in force.
            </p>
          </li>
          <li>
            <h4 class="fw-600">

             5.9 Amendment of the present Data Protection Policy
            </h4>
            <p>
              Weddingaro.com reserves the right to amend this policy in order to
              adapt it future legislative or jurisprudential developments.
            </p>
          </li>
          <li>
            <h4 class="fw-600">

             5.10 Applicable Legislation
            </h4>
            <p>
              This Data Protection Policy and rest of the Legal Conditions of
              the Website are governed in each and every one of its clauses by
              Indian Law.
            </p>
          </li>
          <li>
            <h4 class="fw-600">

             5.11 Rating and Review Disputes
            </h4>
            <div>
              If a User undermine the integrity of our Rating and Review system
              in any way, such User’s membership may be suspended or terminated.
              Each User that is a vendor advertising on the site (a “Vendor
              User”) acknowledges that its Rating and Review ("feedback")
              consists of opinions left by other Users and a machine calculated
              feedback score. You further acknowledge that Weddingaro.com does
              not make judgments on the truth or accuracy of opinions or
              statements. Feedback Rules:
              <br />
              <div>
                <div>
                  1. Users may only leave one feedback item per unique
                  transaction, whether positive, neutral, or negative, for any
                  other Vendor User.
                </div>
                <div>
                  2. The User leaving the review must have conducted business
                  with the Vendor User and be able to provide proof of that
                  business, such as a signed contract or formal proof of payment
                  (i.e., cashed check, credit card statement, paid invoice, or
                  receipt). Weddingaro.com does not accept editable documents or
                  email correspondence. Weddingaro.com reserves the right to
                  request additional information regarding proof of business.
                </div>
                <div>
                  3. By submitting feedback, you agree that feedback is subject
                  to compliance audit by the Weddingaro.com Compliance Team at
                  any time. Any feedback item investigated by the Weddingaro.com
                  Compliance Team may be temporarily or permanently removed.
                </div>
              </div>
              <br />
              In order to protect the integrity of the Rating and Review system,
              Weddingaro.com will only consider removing a feedback item under
              the following scenarios:
              <br />
              <div>
                <div>
                  1. If feedback posted contains language that is profane,
                  vulgar, discriminatory or contains adult material.
                </div>
                <div>
                  2. If Weddingaro.com is provided with a court order directing
                  WeddingWire to remove or modify the feedback.
                </div>
                <div>
                  3. If feedback posted makes any reference to actions taken or
                  purported to be taken by Weddingaro.com or any law enforcement
                  organization.
                </div>
                <div>
                  4. If feedback posted seeks to elicit or solicit any User's
                  contact information, such as other User email addresses, for
                  any non-Weddingaro.com related commercial or business
                  purposes, or to transmit any unsolicited advertising, "junk
                  mail," "spam," or "chain letters".
                </div>
                <div>
                  5. If the User who left feedback cannot be reached by
                  Weddingaro.com or fails, within two months from the date
                  Weddingaro.com attempts to contact such member as part of the
                  dispute process, to validate that the business was conducted
                  with the Vendor User.
                </div>
                <div>
                  6. If feedback contains personally identifiable information of
                  other members, personnel of Vendor Users or any other person.
                  Personally identifiable information includes, but is not
                  limited to, full names, date of birth, physical addresses,
                  email addresses, credit card numbers, or any identification
                  number provided by a government.
                </div>
                <div>
                  7. If required to protect the intellectual property rights of
                  another User.
                </div>
              </div>
            </div>
          </li>
        </ol>
      </li>
    </ol>
  </div>
  <div>
    <h2 class="fw-600" style="font-family: Baskervville">
      Particular Conditions of Use of the WedShoots Application
    </h2>
    <div class="div-webshoot">
      These particular conditions govern the access to and the use of the
      WedShoots Application, developed for its Users with the purpose of
      allowing the creation of photo albums of wedding photographs by
      incorporating them in the pictures taken by the guests.
      <br />
      <br />
      Legal Conditions of the Website, as well as the special conditions of the
      Application. The Application allows Users to insert photographs taken with
      their mobile devices in the photo album created in the Web Site, in the
      site assigned to the Wedding of the Couple.
      <br />
      <br />
      To that end, Users must download the Application on their mobile device
      and access the private area of the Wedding by inserting the corresponding
      QR code provided by the Couple.
      <br />
      <br />
      The Application allows the photos taken by guests to be immediately
      inserted in the Application are intended for the Wedding of the Couple, as
      well as the Wedding photo album located on the Website, with a
      Weddingaro.com watermark.
      <br />
      <br />
      The photos will be the exclusive property of the Couple and therefore the
      Website shall not use them without their express consent. Moreover, all
      the photos removed by the Couple, shall also be removed from the Website
      system, making them inaccessible. Notwithstanding the aforementioned, all
      photos will have the Weddingaro.com watermark.
      <br />
      <br />
      By using the Application, the Guests agree that the photos taken during
      the Wedding are going to be published in the photo album of the Wedding
      located on the Website, as well as any other media that the Application
      allows and that may apply to the photos, as well as the contents inserted
      in the Website, all provided for in the Legal Conditions thereof.
      <br />
      <br />
      Moreover, the Guests agree not to take photographs that may damage the
      image, privacy or honor of other guests at the Wedding, expressly
      exonerating, in any case, the Website from any claim by any third party
      relating to this aspect.
      <br />
      <br />
      The Application is free to use, however there may be functions implemented
      in a new version of the Application that may require the payment of a
      specified price of which the Users will be notified.
      <br />
      <br />
      These particular conditions (the “Particular Conditions”) regulate the
      access and use of the Online Chat Service that Weddingaro.com offers its
      Users to provide assistance and support within the framework of the use of
      the website Weddingaro.com with the goal of optimizing the User experience
      in the search for vendors according to predetermined generic criteria. The
      Weddingaro.com Online Chat Service does not offer, under any
      circumstances, any services related to event planning, but only help in
      the use of the search tools of the Web Site.
      <br />
      <br />
      The terminology used in these particular conditions refers to the
      following concepts:
      <br />
      <ul>
        <li>Web Site: www.Weddingaro.com</li>
        <li>
          Service: Support service in the use of the features of the Web Site
          with the objective of contracting vendors for necessary wedding
          services, by way of Support.
        </li>
        <li>User: User of the Service and, consequently, of the Web Site.</li>
        <li>
          Engaged Couples: Registered Users of the Web Site who are in the
          process of planning a Wedding.
        </li>
        <li>
          Wedding: Event being planned by the Engaged Couples who are Users of
          the Web Site.
        </li>
        <li>
          Support: Employee of Weddingaro.com and reference point for the Users.
        </li>
      </ul>
      <br />
      <br />
      Through the use of the Service, the Users accept the Legal Conditions of
      the Web Site, as well as these particular conditions, aforementioned. The
      Service offers Users assistance via chat and/or phone communication from
      Support with the objective of guiding them and accompanying them in an
      optimized use of the Web Site in accordance with goal detailed above in
      the definition of “Service” above.
      <br />
      <br />
      For this purpose, Weddingaro.com Support contacts users via chat and/or
      the phone number provided by the Users through any method used on the Web
      Site, with the purpose of providing assistance in the use and,
      particularly, in sending inquiries to those professionals in which the
      Engaged Couples could be interested, according to the information gathered
      by Weddingaro.com Support. By providing a phone number to Weddingaro.com,
      the User is consenting to Weddingaro.com using that phone number to
      contact the User and, to that the User is interested in contacting
      professionals, the User also consents to Weddingaro.com providing that
      phone number to the professionals.
    </div>
  </div>
</div>
