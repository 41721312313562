<div class="container">
    <div>
        <div class="wfs-30 baskervil ">Total Users
        </div>
        <p class=" wfs-17 text-black-50">Here are the all the user details </p>

    </div>


    
    <mat-tab-group>

        <mat-tab label="Users">

            <div class="container">
                <div class="row">
                    <div class="col-lg-12">
                        <div class="main-box clearfix">
                            <div class="table-responsive">
                                <table class="table user-list" >
                                    <thead >
                                        <tr>
                                            <th><span>Users</span></th>
                                            <th><span>Created</span></th>
                                            <th class="text-center"><span>Status</span></th>
                                            <th class="text-center" ><span>Email</span></th>
                                            <th>&nbsp;</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr >
                                            <td>
                                                <img loading="lazy" src="https://bootdey.com/img/Content/avatar/avatar1.png" alt="">
                                                <a  class="user-link">Mila Kunis</a>
                                                <span class="user-subhead">Admin</span>
                                            </td>
                                            <td>
                                                2013/08/08
                                            </td>
                                            <td class="text-center">
                                                <span class="label label-default">Inactive</span>
                                            </td>
                                            <td>
                                                <a >mila@kunis.com</a>
                                            </td>
                                            <td style="width: 20%;">
                                                <a  class="table-link">
                                                    <span class="fa-stack">
                                                        <mat-slide-toggle></mat-slide-toggle>
                                                    </span>
                                                </a>
                                                <a  class="table-link">
                                                    <span class="fa-stack">
                                                        <i class="fa fa-square fa-stack-2x"></i>
                                                        <i class="fa fa-pencil fa-stack-1x fa-inverse"></i>
                                                    </span>
                                                </a>
                                                <a  class="table-link danger">
                                                    <span class="fa-stack">
                                                        <i class="fa fa-square fa-stack-2x"></i>
                                                        <i class="fa fa-trash fa-stack-1x fa-inverse"></i>
                                                    </span>
                                                </a>
                                            </td>
                                        </tr>
                                       
                                        <tr>
                                            <td>
                                                <img loading="lazy" src="https://bootdey.com/img/Content/avatar/avatar3.png" alt="">
                                                <a  class="user-link">Ryan Gossling</a>
                                                <span class="user-subhead">Registered</span>
                                            </td>
                                            <td>
                                                2013/03/03
                                            </td>
                                            <td class="text-center">
                                                <span class="label label-danger">Banned</span>
                                            </td>
                                            <td>
                                                <a >jack@nicholson</a>
                                            </td>
                                            <td style="width: 20%;">
                                                <a  class="table-link">
                                                    <span class="fa-stack">
                                                        <mat-slide-toggle></mat-slide-toggle>
                                                    </span>
                                                </a>
                                                <a  class="table-link">
                                                    <span class="fa-stack">
                                                        <i class="fa fa-square fa-stack-2x"></i>
                                                        <i class="fa fa-pencil fa-stack-1x fa-inverse"></i>
                                                    </span>
                                                </a>
                                                <a  class="table-link danger">
                                                    <span class="fa-stack">
                                                        <i class="fa fa-square fa-stack-2x"></i>
                                                        <i class="fa fa-trash fa-stack-1x fa-inverse"></i>
                                                    </span>
                                                </a>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>
                                                <img loading="lazy" src="https://bootdey.com/img/Content/avatar/avatar4.png" alt="">
                                                <a  class="user-link">Emma Watson</a>
                                                <span class="user-subhead">Registered</span>
                                            </td>
                                            <td>
                                                2004/01/24
                                            </td>
                                            <td class="text-center">
                                                <span class="label label-warning">Pending</span>
                                            </td>
                                            <td>
                                                <a >humphrey@bogart.com</a>
                                            </td>
                                            <td style="width: 20%;">
                                                <a  class="table-link">
                                                    <span class="fa-stack">
                                                        <mat-slide-toggle></mat-slide-toggle>
                                                    </span>
                                                </a>
                                                <a  class="table-link">
                                                    <span class="fa-stack">
                                                        <i class="fa fa-square fa-stack-2x"></i>
                                                        <i class="fa fa-pencil fa-stack-1x fa-inverse"></i>
                                                    </span>
                                                </a>
                                                <a  class="table-link danger">
                                                    <span class="fa-stack">
                                                        <i class="fa fa-square fa-stack-2x"></i>
                                                        <i class="fa fa-trash fa-stack-1x fa-inverse"></i>
                                                    </span>
                                                </a>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>
                                                <img loading="lazy" src="https://bootdey.com/img/Content/avatar/avatar5.png" alt="">
                                                <a  class="user-link">Robert Downey Jr.</a>
                                                <span class="user-subhead">Admin</span>
                                            </td>
                                            <td>
                                                2013/12/31
                                            </td>
                                            <td class="text-center">
                                                <span class="label label-success">Active</span>
                                            </td>
                                            <td>
                                                <a >spencer@tracy</a>
                                            </td>
                                            <td style="width: 20%;">
                                                <a  class="table-link">
                                                    <span class="fa-stack">
                                                        <mat-slide-toggle></mat-slide-toggle>
                                                    </span>
                                                </a>
                                                <a  class="table-link">
                                                    <span class="fa-stack">
                                                        <i class="fa fa-square fa-stack-2x"></i>
                                                        <i class="fa fa-pencil fa-stack-1x fa-inverse"></i>
                                                    </span>
                                                </a>
                                                <a  class="table-link danger">
                                                    <span class="fa-stack">
                                                        <i class="fa fa-square fa-stack-2x"></i>
                                                        <i class="fa fa-trash fa-stack-1x fa-inverse"></i>
                                                    </span>
                                                </a>
                                            </td>
                                        </tr>
                                       
                                    </tbody>
                                </table>
                            </div>
                           
                        </div>
                    </div>
                </div>
            </div>

        </mat-tab>

        <mat-tab label="Vendors">

            <div class="container">
                <div class="row">
                    <div class="col-lg-12">
                        <div class="main-box clearfix">
                            <div class="table-responsive">
                                <table class="table user-list">
                                    <thead>
                                        <tr>
                                            <th><span>Vendors</span></th>
                                            <th><span>Created</span></th>
                                            <th class="text-center"><span>Status</span></th>
                                            <th><span>Email</span></th>
                                            <th>&nbsp;</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td>
                                                <img loading="lazy" src="https://bootdey.com/img/Content/avatar/avatar1.png" alt="">
                                                <a  class="user-link">Mila Kunis</a>
                                                <span class="user-subhead">Admin</span>
                                            </td>
                                            <td>
                                                2013/08/08
                                            </td>
                                            <td class="text-center">
                                                <span class="label label-default">Inactive</span>
                                            </td>
                                            <td>
                                                <a >mila@kunis.com</a>
                                            </td>
                                            <td style="width: 20%;">
                                                <a  class="table-link">
                                                    <span class="fa-stack">
                                                        <mat-slide-toggle></mat-slide-toggle>
                                                    </span>
                                                </a>
                                                <a  class="table-link">
                                                    <span class="fa-stack">
                                                        <i class="fa fa-square fa-stack-2x"></i>
                                                        <i class="fa fa-pencil fa-stack-1x fa-inverse"></i>
                                                    </span>
                                                </a>
                                                <a  class="table-link danger">
                                                    <span class="fa-stack">
                                                        <i class="fa fa-square fa-stack-2x"></i>
                                                        <i class="fa fa-trash fa-stack-1x fa-inverse"></i>
                                                    </span>
                                                </a>
                                            </td>
                                        </tr>
                                       
                                        <tr>
                                            <td>
                                                <img loading="lazy" src="https://bootdey.com/img/Content/avatar/avatar3.png" alt="">
                                                <a  class="user-link">Ryan Gossling</a>
                                                <span class="user-subhead">Registered</span>
                                            </td>
                                            <td>
                                                2013/03/03
                                            </td>
                                            <td class="text-center">
                                                <span class="label label-danger">Banned</span>
                                            </td>
                                            <td>
                                                <a >jack@nicholson</a>
                                            </td>
                                            <td style="width: 20%;">
                                                <a  class="table-link">
                                                    <span class="fa-stack">
                                                        <mat-slide-toggle></mat-slide-toggle>
                                                    </span>
                                                </a>
                                                <a  class="table-link">
                                                    <span class="fa-stack">
                                                        <i class="fa fa-square fa-stack-2x"></i>
                                                        <i class="fa fa-pencil fa-stack-1x fa-inverse"></i>
                                                    </span>
                                                </a>
                                                <a  class="table-link danger">
                                                    <span class="fa-stack">
                                                        <i class="fa fa-square fa-stack-2x"></i>
                                                        <i class="fa fa-trash fa-stack-1x fa-inverse"></i>
                                                    </span>
                                                </a>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>
                                                <img loading="lazy" src="https://bootdey.com/img/Content/avatar/avatar4.png" alt="">
                                                <a  class="user-link">Emma Watson</a>
                                                <span class="user-subhead">Registered</span>
                                            </td>
                                            <td>
                                                2004/01/24
                                            </td>
                                            <td class="text-center">
                                                <span class="label label-warning">Pending</span>
                                            </td>
                                            <td>
                                                <a >humphrey@bogart.com</a>
                                            </td>
                                            <td style="width: 20%;">
                                                <a  class="table-link">
                                                    <span class="fa-stack">
                                                        <mat-slide-toggle></mat-slide-toggle>
                                                    </span>
                                                </a>
                                                <a  class="table-link">
                                                    <span class="fa-stack">
                                                        <i class="fa fa-square fa-stack-2x"></i>
                                                        <i class="fa fa-pencil fa-stack-1x fa-inverse"></i>
                                                    </span>
                                                </a>
                                                <a  class="table-link danger">
                                                    <span class="fa-stack">
                                                        <i class="fa fa-square fa-stack-2x"></i>
                                                        <i class="fa fa-trash fa-stack-1x fa-inverse"></i>
                                                    </span>
                                                </a>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>
                                                <img loading="lazy" src="https://bootdey.com/img/Content/avatar/avatar5.png" alt="">
                                                <a  class="user-link">Robert Downey Jr.</a>
                                                <span class="user-subhead">Admin</span>
                                            </td>
                                            <td>
                                                2013/12/31
                                            </td>
                                            <td class="text-center">
                                                <span class="label label-success">Active</span>
                                            </td>
                                            <td>
                                                <a >spencer@tracy</a>
                                            </td>
                                            <td style="width: 20%;">
                                                <a  class="table-link">
                                                    <span class="fa-stack">
                                                        <mat-slide-toggle></mat-slide-toggle>
                                                    </span>
                                                </a>
                                                <a  class="table-link">
                                                    <span class="fa-stack">
                                                        <i class="fa fa-square fa-stack-2x"></i>
                                                        <i class="fa fa-pencil fa-stack-1x fa-inverse"></i>
                                                    </span>
                                                </a>
                                                <a  class="table-link danger">
                                                    <span class="fa-stack">
                                                        <i class="fa fa-square fa-stack-2x"></i>
                                                        <i class="fa fa-trash fa-stack-1x fa-inverse"></i>
                                                    </span>
                                                </a>
                                            </td>
                                        </tr>
                                       
                                    </tbody>
                                </table>
                            </div>
                           
                        </div>
                    </div>
                </div>
            </div>

        </mat-tab>
        
    </mat-tab-group>

    







</div>