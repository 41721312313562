import { Component } from '@angular/core';

@Component({
  selector: 'app-weddingvendors',
  templateUrl: './weddingvendors.component.html',
  styleUrls: ['./weddingvendors.component.scss']
})
export class WeddingvendorsComponent {


}
