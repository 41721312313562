<ng-container *ngFor="let item of faq; let i = index">
  <div class="card card-body">
    <div (click)="toggleCollapse(i)"
      class="d-flex flex-row justify-content-between align-items-center custom-text-hover"
      [ngClass]="{ 'custom-text-color': expanded && currentCollapse === i }">
      <div class="collapse-button">
        {{ item.heading }}
      </div>
      <div>
        <i class="fas fa-angle-down" *ngIf="!expanded || i !== currentCollapse"></i>
        <i class="fas fa-angle-up" *ngIf="expanded && i === currentCollapse"></i>
      </div>
    </div>
    <div class="collapse-div" [ngClass]="{ expanded: expanded && i === currentCollapse }" *ngIf="i === currentCollapse">
      {{ item.discription }}
    </div>
  </div>
</ng-container>