import { Component } from '@angular/core';

@Component({
  selector: 'app-budgetscreen',
  templateUrl: './budgetscreen.component.html',
  styleUrls: ['./budgetscreen.component.scss']
})
export class BudgetscreenComponent {

}
