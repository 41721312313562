<div style="font-family: Baskervville" class="min-vh-60 p-5 contact-div">
  <h2 class="fw-600">About Us</h2>
  <div class="mt-4 d-flex flex-column gap-5">
    <img loading="lazy" class="img-fluid" src="../../../../assets/images/aboutus.svg" alt="">
    <p class="fs-20">
      Founded in 2018, Weddingaro.com is a product of TRIFECTA NET PVT LTD, an
      Indian IT company. Weddingaro.com team is making it easier to find and
      book venues and vendors across 30+ cities in India. We are a young team
      that loves to find all kinds of trendy, chic, professional, or unique
      venues and vendors. We are building a product that showcases the amazing
      spaces and event professionals we come across and at the same time, we aim
      to help event organizers save time and effort when looking for their kind
      of venues and event partners. The ultimate objective is to connect the
      right people to the right kind of venues and vendors, bridging the gap and
      delivering value!
    </p>
    <div>
      <h2 class="fw-600">THE TEAM</h2>
      <p class="fs-20">
        Weddingaro.com has a passionate, committed man founder at its helm with
        some sharpest, experienced brains behind it. As with any great team,
        Weddingaro.com is a good mix of sharp, intelligent, focused,
        hard-working team. We're young, we're enthusiastic and yes, we love
        hunting down the coolest venues and event vendors around.
      </p>
    </div>
    <div >
      <h2 class="fw-600">CORE VALUES</h2>
      <div >
        <div>
          <h3 class="fw-600">Innovation</h3>
          <p class="fs-20">
            Innovation is at the core of our business values. Our team is
            constantly thinking on creating a better user experience, and
            offering unique, state-of-the-art services to our users.
          </p>
        </div>
        <div>
          <h3 class="fw-600"> Openness</h3>
          <p class="fs-20">
            We encourage every team member to share their opinions, ideas and
            feedback openly. This helps in building strong relationships, better
            products and processes.
          </p>
        </div>
        <div>
          <h3 class="fw-600">Change</h3>
          <p class="fs-20">
            We are evolving and believe in creating value by driving change in
            the way things are done at present. We are happy to embrace every
            opportunity with open arms.
          </p>
        </div>
        <div>
          <h3 class="fw-600">Ownership</h3>
          <p class="fs-20">
            Every member of our team takes complete ownership of the task they
            take up. We encourage collaboration while building individual
            accountability.
          </p>
        </div>
        <div>
          <h3 class="fw-600">Honesty</h3>
          <p class="fs-20">
            We believe in doing and communicating the right thing under all
            circumstances
          </p>
        </div>
      </div>
    </div>
  </div>
</div>
