<div class="container-fluid">
  <div class="container-fluid position-relative">
    <img loading="lazy" src="/assets/images/favouriteBG.svg" alt="" class="img-fluid" style="width: 100%; height: 400px;">
    <div class="centered-card rounded-4 shadow">
      <div class="card-body">
        <div class="text-center fw-400" style="font-family: Baskerville; font-size: 3vw;">
          Favourites
        </div>
        <div class="text-center m-2"
          style="font-family: Baskerville; font-size: 1.5vw; font-weight: lighter !important ;">
          Keep all of your wedding favourites here! Click the to save your favourite vendors, Real
          Weddings, and inspiration here.
        </div>
      </div>
    </div>
  </div>

  <div class="text-center py-4 fw-500 fs-30">
    Favourite Vendors
  </div>

  <ng-template #noData>
    <div class="text-center">
      <img loading="lazy" src="https://weddingaro.s3.ap-south-1.amazonaws.com/side-images/no-favroite.png" height="400" width="400" alt="" srcset="">
    </div>
  </ng-template>

  <div class="d-flex flex-wrap justify-content-center">
    <ng-container *ngIf="items.length else noData">
      <div *ngFor="let item of items" class="grid-item zoom-img mr-10 cursor-hover">
        <div (click)="goToAllVendorByCategory(item._id)">
          <img loading="lazy" src="/assets/images/Venue.svg" alt="" srcset="" />
          <div class="overlay"></div>
          <div class="centered-text">
            <img loading="lazy" src="/assets/images/Home.svg" alt="" height="45" width="45">
            <h5 class="mt-2">{{ item.name }}</h5>
            <h6 class="mt-2"> {{ item.store.length }} saved</h6>
          </div>
        </div>
      </div>
    </ng-container>
  </div>
</div>