<div class="w-[100vw]   " style="background-color: transparent;"  >
  <div class="d-flex justify-content-around h-auto footer-container font-inter w-100 text-start  " style="border-top-right-radius: 30px !important; border-top-left-radius: 30px !important;" >
    <div class=" ">
      <div class="main-font  mt-5">Information</div>
      <br />
      <ul class="text-start list-unstyled correction">
        <li class="cursor-hover-red" routerLink="/terms-and-condition" appScrollToTop>
          Terms & Privacy
        </li>
        <li class="cursor-hover-red" routerLink="/privacy-and-policy" appScrollToTop>Privacy & Policy</li>
        <!-- <li class="d-none">Do Not Sell My Personal Information</li> -->
        <li [routerLink]="getUserLink()"  class="cursor-hover-red" appScrollToTop>Register Your Business</li>
        <li class="cursor-hover-red" routerLink="/about-us" appScrollToTop>About Us</li>
        <li class="cursor-hover-red" routerLink="/career" appScrollToTop>Careers</li>
        <li class="cursor-hover-red" routerLink="/contact-us" appScrollToTop>Contact us</li>
      </ul>
    </div>
    <div class="">
      <div class="mt-5 main-font text-bold  ">
        Contact us
      </div>
      <br />
      <ul class="text-start list-unstyled fs-14 correction">
        <li>Contact our Expert for Free</li>
        <!-- <li class="custom-text-color"> <i class="fa-solid fa-phone me-2  custom-text-color"></i> Call 7290006773</li> -->
        <a href="tel:+917290006773" style="text-decoration: none">
          <li class="custom-text-color">
            <i class="fa-solid fa-phone me-2 custom-text-color"></i> Call +91
            7290006773
          </li>
        </a>
        <a href="mailto: hello@weddingaro.com" style="text-decoration: none">
          <li class="custom-text-color">
            <i class="fa-solid fa-envelope me-2 custom-text-color"></i>
            hello@weddingaro.com
          </li>
        </a>
      </ul>
    </div>
    <div class="">
      <div class="mt-5 main-font text-bold ">Follow us on</div>
      <br />
      <ul class="text-start list-unstyled correction">
        <li>
          <a href="https://www.facebook.com/profile.php?id=100084042096560" target="_blank">
            <img loading="lazy" class="mx-1" src="/assets/images/Facebook.png" alt="" srcset="" />
          </a>
          <a href="https://twitter.com/weddingaro8" target="_blank">
            <img loading="lazy" class="mx-1" src="/assets/images/Twitter.png" alt="" srcset="" />
          </a>
          <a href="https://instagram.com/weddingaro8?igshid=MzNlNGNkZWQ4Mg==" target="_blank">
            <img loading="lazy" class="mx-1" src="/assets/images/Instagram.png" alt="" srcset="" />
          </a>
        </li>
      </ul>
    </div>
  </div>
</div>
