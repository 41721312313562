<app-navbar class="mt-90" *ngIf="showNavbarAndFooter"></app-navbar>

<router-outlet></router-outlet>

<ng-container *ngIf="loaderService.isLoading$ | async">
  <div class="loader-overlay">
    <app-loader></app-loader>
  </div>
</ng-container>

<app-footer *ngIf="showNavbarAndFooter"></app-footer>
