<div class="container">
  <div class="mt-70" *ngFor="let venue of venues">
    <ng-container class="m-0 p-0" *ngIf="venue.city">
  
      <div class="venue-heading">Grooms in {{ venue.city }}</div>
      <app-custom-slider [g]="20">
        <div class="venue-carousel-wrapper ">
  
          <ng-container *ngFor="let data of venue.venues">
            <app-venue-card [cardInput]="data"></app-venue-card>
          </ng-container>
        </div>
  
      </app-custom-slider>
      <!-- <div class="button-link">
      <a class="btn" role="button">Discover {{venue.venues.length}} venues in {{ venue.city }} <i class="fas fa-arrow-right"></i></a>
    </div> -->
    </ng-container>
</div>