 <div class="container">
                <div class="d-flex justify-content-between align-items-center">
                    <div>
                        <div class="wfs-30 " style="font-family: Baskervville;">Welcome Admin <strong>"Deepak
                                Dangi"</strong>
                        </div>
                        <p class=" wfs-17 text-black-50">See the analytics to you dashboard here.</p>

                    </div>


                    <div class=" d-flex justify-content align-items-center">

                        <div class=" ">
                            <mat-icon [ngClass]="{'active': true}" routerLink="fav-vendor">favorite</mat-icon>

                        </div>
                        <div class="m-3  cursor-hover-red " routerLink="user-dashboard/my-wedding/user-inbox">
                            <mat-icon>message</mat-icon>
                        </div>


                        <img loading="lazy" class="active custom-text-hover" type="button" src="/assets/images/profilePic.png"
                            style="border-radius: 50%; object-fit: cover;" height="40" width="40">
                    </div>
                </div>
                <div class="h-100 w-100">
                    <div class="dashboardCard_Container">
                        <ng-container *ngFor="let item of cards">
                            <div class="rounded-2 border-1 card text-center">
                                <div class=" d-flex align-items-center text-center justify-content-center mt-4 ">
                                    <mat-icon class="iconsizeMAT iconcolor ">{{ getIconForCardsTitle(item.title)
                                        }}</mat-icon>
                                    <span class="text-bold fs-3 align-middle mx-1">0</span>
                                </div>
                                <div>{{item.title}}</div>
                                <div class="text-muted  mt-1">in the last 12 months</div>
                            </div>
                        </ng-container>
                    </div>


                </div>

            </div>