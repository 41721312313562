<div class="container-fluid">
  <div class="row p-0 m-0">
    <div class="col-12"> <!-- Full width on small screens, stacked on larger screens -->
      
      
         <button mat-raised-button color="warn" class="my-3" routerLink="business-details" (click)="removeStoreid()">Add
        Business</button>
        <input  type="text" class="form-control"  placeholder="Search by business name" [(ngModel)]="searchQuery" (input)="applyFilter()">

      
     

        
        <!-- Modify the keyup event binding to pass the event object -->
       
        <ng-container class="mt-2" *ngIf="filteredStore.length > 0; else noData">
          <div class="mat-table-responsive">
            <table mat-table [dataSource]="filteredStore" class="mat-elevation-z8 demo-table">
              <!-- Position Column -->
              <ng-container matColumnDef="name">
                <th mat-header-cell *matHeaderCellDef>Business Name</th>
                <td class="cursor-hover-red" mat-cell *matCellDef="let element"
                  [routerLink]="['/venues-details', element.storeId]">
                  {{element.name}}
                </td>
              </ng-container>
        
              <!-- Name Column -->
              <ng-container matColumnDef="mobile">
                <th mat-header-cell *matHeaderCellDef>Mobile</th>
                <td mat-cell *matCellDef="let element">{{element.mobile}}</td>
              </ng-container>
        
              <!-- Weight Column -->
              <ng-container matColumnDef="email">
                <th mat-header-cell *matHeaderCellDef>Email</th>
                <td mat-cell *matCellDef="let element">{{element.email}}</td>
              </ng-container>
        
              <ng-container matColumnDef="location">
                <th mat-header-cell *matHeaderCellDef>Location</th>
                <td mat-cell *matCellDef="let element">{{element.location}}</td>
              </ng-container>
        
              <!-- Symbol Column -->
              <ng-container matColumnDef="action">
                <th mat-header-cell *matHeaderCellDef>Action</th>
                <td mat-cell *matCellDef="let element">
                  <button mat-button (click)="confirmDelete(element.id)">
                    <mat-icon>delete</mat-icon>
                  </button>
        
                  <button mat-button [routerLink]="['business-details', element.storeToken]">
                    <mat-icon>create</mat-icon>
                  </button>
                </td>
              </ng-container>
        
              <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
              <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
            </table>
          </div>
        </ng-container>
        <ng-template #noData>
          <div class="text-center">
            <img loading="lazy" src="https://weddingaro.s3.ap-south-1.amazonaws.com/side-images/no-data.png" height="400" width="500" alt="" srcset="">
          </div>
        </ng-template>
    </div>
  </div>
</div>