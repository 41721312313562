import { Component } from '@angular/core';

@Component({
  selector: 'app-setting-content',
  templateUrl: './setting-content.component.html',
  styleUrls: ['./setting-content.component.scss']
})
export class SettingContentComponent {

}
