import { Component } from '@angular/core';

@Component({
  selector: 'app-privacyandpolicy',
  templateUrl: './privacyandpolicy.component.html',
  styleUrls: ['./privacyandpolicy.component.scss']
})
export class PrivacyandpolicyComponent {

}
