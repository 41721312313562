<div class="container">


  <div class="text-center fs-35 fw-400 mb-4" style="font-family: Baskervville;">
    Vendor Manager
  </div>


  <div class="container text-center col-10">
    <div class="d-flex justify-content-between align-items-center ">
      <div>
        <!-- <p class=" fs-17 text-black-50">0 OF 18 VENDORS BOOKED</p>
  
          <app-progress-bar [progress]="50"></app-progress-bar> -->
      </div>


      <div class="btn-group-toggle" data-toggle="buttons">
        <button class="btn " style="border-radius: 0;"
          [ngClass]="{ 'bg-light-red': selectedButton === 'option1', 'white': selectedButton !== 'option1' }"
          (click)="selectButton('option1')">

          <img loading="lazy" src="/assets/images/Bookmark.svg" alt="" height="30px" width="30px">
          {{totalstores}} Saved <span class="text-black-50"></span>
        </button>
        <!-- <button
            class="btn" style="border-radius: 0;"
            [ngClass]="{ 'bg-light-red': selectedButton === 'option2', 'white': selectedButton !== 'option2' }"
            (click)="selectButton('option2')"
          >
          <img loading="lazy" src="/assets/images/Ok.svg" alt="" height="30px" width="30px">
            Hired <span class="text-black-50">0</span>
          </button> -->
      </div>

      <button type="button" class="btn btn-primary custom-button-dark m-3" routerLink="/home">Add Vendor</button>
      <div></div>
    </div>
  </div>



  <hr class="mt-4 mb-4 ">

  <div *ngIf="selectedButton == 'option2'" class="text-center align-items-center m-3">
    <strong>Keep searching for your wedding vendors</strong><br><br>
    ALREADY HIRED? ADD THEM!
  </div>
  <div class="d-flex flex-wrap justify-content-center">
    <ng-container *ngIf="items.length else noData">
      <div *ngFor="let item of items" class="grid-item zoom-img mr-10 cursor-hover">
        <div (click)="goToAllVendorByCategory(item._id)">
          <img loading="lazy" src="/assets/images/Venue.svg" alt="" srcset="" />
          <div class="overlay"></div>
          <div class="centered-text">
            <img loading="lazy" src="/assets/images/Home.svg" alt="" height="45" width="45">
            <h5 class="mt-2">{{ item.name }}</h5>
            <h6 class="mt-2"> {{ item.store.length }} saved</h6>
          </div>
        </div>
      </div>
    </ng-container>
  </div>
  
  <ng-template #noData>
    <div class="text-center ">
      <img loading="lazy" src="https://weddingaro.s3.ap-south-1.amazonaws.com/side-images/no-favroite.png" height="400" width="400" alt="" srcset="">
    </div>
  </ng-template>



</div>