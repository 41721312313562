<div class="custom-card-container m-4" *ngIf="type=='wedding-vendor'">
  <div>
    <img loading="lazy" class="custom-card-image mx-3" src="/assets/images/wedding-banquet.png" alt="" srcset="">
  </div>
  <div class="custom-card-body p-3">
    <span class="custom-card-title"> Title Text </span>
    <div class="row mt-2 fs-12 font-inter">
      <div class="col-6">
        <i class="fa-solid fa-star custom-text-color"></i><i class="fa-solid fa-star custom-text-color"></i><i class="fa-solid fa-star custom-text-color"></i><i class="fa-solid fa-star custom-text-color"></i><i class="fa-solid fa-star custom-text-color"></i> 5
      </div>
      <div class="col-6 text-muted" > <i class="fa-sharp fa-solid fa-location-dot mx-1 custom-text-color"></i> Gurgaon, Haryana</div>

    </div>
    <div class="row mt-2 fs-12 font-inter">
      <div class="col-6"><i class="fa-solid fa-utensils mx-1 custom-text-color"></i> From ₹1,500 </div>
      <div class="col-6"> <i class="fa-solid fa-users mx-1 custom-text-color"></i>100 to 200 </div>
    </div>
  </div>
</div>

<div class="custom-card-container m-4" *ngIf="type=='wedding-couple'">
  <div>
    <img loading="lazy" class="custom-card-image mx-3" src="/assets/images/wedding-photo.png" alt="" srcset="">
  </div>
  <div class="custom-card-body p-3">
    <span class="custom-card-title"> Suminder & Deepika </span>
    <div class=" mt-2 fs-12 font-inter">
      <div class="col-6">
        <i class="fa-solid fa-image mx-1 custom-text-color"></i> 10 Photos
      </div>
      <div class="col-6 text-muted" > <i class="fa-sharp fa-solid fa-location-dot mx-1 custom-text-color"></i> Gurgaon, Haryana</div>

    </div>
  </div>
</div>
