<div
          class="text-black fs-3"
          style="
            font-family: Baskervville;
            font-weight: 400;

            word-wrap: break-word;
          "
        >
          Settings
        </div>

        <div
          class="text-black fs-5"
          style=" font-weight: 400"
        >
          Wedding Wire message notifications
        </div>
        <div class="container mt-4">
          <div class="row justify-content-center">
            <div class="col-lg-12 col-md-8 col-sm-12">
              <div class="position-relative bg-white p-4 rounded-lg shadow">
                <div class="mb-4">
                  <div class="input-group">
                    <span class="input-group-text">
                      <img
              loading="lazy"          src="assets/images/inboxIcon.svg"
                        style="width: 25px; height: 25px"
                        alt="user-avatar"
                      />
                    </span>
                    <input
              type="text"
              class="form-control col-lg-9 col-md-8 col-sm-7 col-6"
              value="xyz@gmail.com"
              disabled
              style="
                font-size: 18px;
                
                font-weight: 400;
                background-color: white;
              "
            />
            <div
            class="mt-2 m-2 col-lg-3 col-md-4 col-sm-5 col-6"
            style="
              font-size: 20px;
              font-style: italic;
              font-weight: 400;
              color: rgba(0, 0, 0, 0.5);
            "
          >
                      (by default)
                    </div>
                  </div>

                  <div
                    class="text-black-60 fs-6 mt-2"
                    style=" line-height: 1.5"
                  >
                    Add any email that you'd like to receive notifications about
                    new messages.
                  </div>
                  <div class="input-group mt-3">
                    <input
                      type="text"
                   
                      class="form-control col-lg-9 col-md-8 col-sm-7 col-6"
                      placeholder="Enter email"
                      style="
                        font-size: 17px;
                        
                        font-weight: 400;
                      "
                    />
                  </div>
                </div>
                <div>
                  <button
                    class="btn btn-danger"
                    style="
                      font-size: 18px;
                      
                      font-weight: 500;
                    "
                  >
                    Add emails
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      