<div class="container p-3">
  <div
    style="display: flex; justify-content: space-between; align-items: center"
  >
    <img
      loading="lazy"
      src="/assets/images/logo_desktop.png"
      routerLink="/home"
      alt="Logo"
      height="75"
      class="img-fluid"
      style="max-width: 185px"
    />
    <h2 mat-dialog-title>
      <button mat-icon-button mat-dialog-close aria-label="Close">
        <mat-icon>close</mat-icon>
      </button>
    </h2>
  </div>

  <form class="example-form mt-2 mb-3" [formGroup]="loginForm">
    <div class="formfield_div">
      <div>
        <mat-form-field class="example-full-width w-100">
          <mat-label>Email</mat-label>
          <input type="email" matInput formControlName="username" />
        </mat-form-field>
      </div>
    </div>
    <div class="formfield_div">
      <div>
        <mat-form-field class="example-full-width w-100">
          <mat-label>Password</mat-label>
          <input type="password" matInput formControlName="password" />
        </mat-form-field>
      </div>
    </div>
    <div class="text-center m-2">
      <button
        class="btn custom-button w-100 text-white"
        (click)="login()"
        [disabled]="this.loginForm.invalid"
        [ngClass]="{ 'red-text': this.loginForm.invalid }"
      >
        Login
      </button>
    </div>
  </form>
  <div class="d-flex justify-content-center">
    New User?
    <span class="custom-text-color cursor ml-4" [routerLink]="'/signup'"
      >Sign Up</span
    >
  </div>
  <div class="d-flex w-100 justify-content-center mt-4" *ngIf="role == 'user'">
    <hr style="width: 210px" class="mx-2" />
    <span class="mt-1">Or</span>
    <hr style="width: 210px" class="mx-2" />
  </div>
  <div class="mt-3 mb-2 text-center" *ngIf="role == 'user'">
    <button class="btn btn-social-icon btn-google" (click)="signInWithGoogle()">
      <asl-google-signin-button type="standard" size="large">
      </asl-google-signin-button>
    </button>
    <!-- <img loading="lazy" src="/assets/images/facebook-signup.png" class="mt-2 auth-signup" alt="/assets/" srcset="">
    <img loading="lazy" src="/assets/images/google-signup.png" class="mt-2 auth-signup" alt="/assets/" srcset="">
    <img loading="lazy" src="/assets/images/apple-signup.png" class="mt-2 auth-signup" alt="/assets/" srcset=""> -->
  </div>
</div>
