import { Component } from '@angular/core';

@Component({
  selector: 'app-wedding-vendors',
  templateUrl: './wedding-vendors.component.html',
  styleUrls: ['./wedding-vendors.component.scss']
})
export class WeddingVendorsComponent {

  productId: string = '';
  storess = [];
 
  
  venuesList = [
   
  ];
 
  



}
